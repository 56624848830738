import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, CircularProgress, Stack, IconButton } from '@mui/material';
import { getFirestore, collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { databaseFirestore } from '../../Firebase';
import { useNavigate } from 'react-router';
import { BackdropLoader } from '../UniversalFunctions';

const Downloads = () => {
    const [downloads, setDownloads] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDownloads = async () => {
            setLoading(true);
            try {
                const q = query(collection(databaseFirestore, 'downloads'), orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const downloadsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDownloads(downloadsList);
            } catch (error) {
                console.error('Error fetching downloads:', error);
            }
            setLoading(false);
        };

        fetchDownloads();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(databaseFirestore, 'downloads', id));
            setDownloads(downloads.filter((download) => download.id !== id));
        } catch (error) {
            console.error('Error deleting download:', error);
        }
    };

    return (
        <div className="appContainer">
            {loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate("/") }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Downloads </Typography>
                        </Stack>
                        <Button variant='contained' color='primary' onClick={() => { navigate("/addfile") }} disableElevation>Add New</Button>
                    </Stack>
                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        Downloads
                    </Typography>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        downloads.map((download) => (
                            <Box
                                key={download.id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                padding="10px"
                                margin="10px 0"
                                bgcolor="#fff"
                                borderRadius="4px"
                                boxShadow="0 0 5px rgba(0, 0, 0, 0.1)"
                            >
                                <Box>
                                    <Typography variant="body1">{download.title}</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {new Date(download.createdAt.seconds * 1000).toLocaleString()}
                                    </Typography>
                                </Box>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href={download.fileUrl}
                                        download={download.fileName}
                                    >
                                        Download
                                    </Button>
                                    <IconButton onClick={() => handleDelete(download.id)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            </Box>
                        ))
                    )}
                </Box>
            </div>
        </div>
    );
};

export default Downloads;
