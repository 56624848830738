import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { AuthVariable } from '../Firebase';
import '../Styles/home.css'
import Button from '@mui/material/Button'
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { superAdminMail } from './Variables';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import NumbersIcon from '@mui/icons-material/Numbers';
import CreateIcon from '@mui/icons-material/Create';
import CollectionsIcon from '@mui/icons-material/Collections';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BookIcon from '@mui/icons-material/Book';
import EventIcon from '@mui/icons-material/Event';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutIcon from '@mui/icons-material/Logout';
import { BookSharp, Menu, VerifiedUser } from '@mui/icons-material';

export default function Home({ accessLevel, userData }) {
    // console.log(userData);

    const navigate = useNavigate()
    const [AccessLevel, setAccessLevel] = useState(null)

    // function for logging out from the current acount.
    const LOGGIN_OUT = () => {
        signOut(AuthVariable)
            .then((res) => { console.log(res); })
            .catch((err) => { console.log(err); })
    }


    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box bgcolor="white" pl={2} pr={2} pb={2} pt={1} position="fixed" top={0} zIndex={1} borderBottom="1px solid lightgrey" width="100%" maxWidth="640px">
                    <Stack width="100%">
                        <Typography variant='h6' textTransform="uppercase">{userData?.userName}</Typography>
                        <Typography variant='body2'>{accessLevel == '1' || accessLevel == '0' ? 'Tap the section you want to update' : ''}</Typography>
                        <Typography variant='body2' color="grey">Current User : {userData?.userEmail}</Typography>
                    </Stack>
                </Box>
                <Box p={2} mt={9}>
                    <br />
                    <Stack direction="column" spacing={2}>

                        {/* button for only super admin */}
                        {
                            accessLevel == '0' ? <>
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/addbulkschools") }}
                                    primaryText={"Add Bulk Schools"}
                                    secondaryText={"Add multiple schools in bulk. This feature is currently available only for superadmins."}
                                    color={"error"}
                                    icon={<AddBusinessIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                onClickAction={() => { navigate("/adduser") }}
                                primaryText={"Add Users"}
                                secondaryText={"Add new users.This feature is currently available only for superadmins."}
                                color={"error"}
                                icon={<VerifiedUser sx={{ width: "16px" }} />}
                            /> 
                            </> : ""}

                        {/* buttons for admin  */}
                        {
                            accessLevel == '0' || accessLevel == '1' ? <>
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/banners") }}
                                    primaryText={"Home Page Banners"}
                                    secondaryText={"Update the images on your website's landing page."}
                                    color={"secondary"}
                                    icon={<ViewCarouselIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/about/aboutschool") }}
                                    primaryText={"School Basic Details"}
                                    secondaryText={"Edit basic details such as stats, contacts, address, and social links on your website."}
                                    color={"secondary"}
                                    icon={<NumbersIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/notices") }}
                                    primaryText={"Notice Board"}
                                    secondaryText={"Publish new notices on your website."}
                                    color={"secondary"}
                                    icon={<CreateIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/gallery") }}
                                    primaryText={"Gallery"}
                                    secondaryText={"Manage the images in your website's gallery."}
                                    color={"secondary"}
                                    icon={<CollectionsIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/halloffame") }}
                                    primaryText={"Hall of Fame"}
                                    secondaryText={"Manage the Hall of Fame section on your website, including adding and deleting entries."}
                                    color={"secondary"}
                                    icon={<EmojiEventsIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/blogs") }}
                                    primaryText={"Blogs"}
                                    secondaryText={"Publish and manage blogs on your website."}
                                    color={"secondary"}
                                    icon={<BookIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/events") }}
                                    primaryText={"Recent Events"}
                                    secondaryText={"Manage events on your website, including adding and deleting events."}
                                    color={"secondary"}
                                    icon={<EventIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/schools") }}
                                    primaryText={"Schools"}
                                    secondaryText={"View and manage all listed schools, including adding, removing, and updating details."}
                                    color={"secondary"}
                                    icon={<ApartmentIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/downloads") }}
                                    primaryText={"Downloads"}
                                    secondaryText={"Manage all downloadable materials available on your website."}
                                    color={"secondary"}
                                    icon={<DownloadIcon sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/forms") }}
                                    primaryText={"Forms"}
                                    secondaryText={"Create dynamic forms and publish it to take inputs from all 20 schools"}
                                    color={"secondary"}
                                    icon={<Menu sx={{ width: "16px" }} />}
                                />
                                <HomeButtonComponent
                                    onClickAction={() => { navigate("/syllabus") }}
                                    primaryText={"पाठ्यक्रम / Syllabus"}
                                    secondaryText={"Manage syllabus of the all classes from here. Add/Remove classes or Materials"}
                                    color={"secondary"}
                                    icon={<BookSharp sx={{ width: "16px" }} />}
                                />
                            </>
                                : null
                        }

                        {/* buttons for school users */}
                        {
                            accessLevel == '2' ?
                                <>
                                    <HomeButtonComponent
                                        onClickAction={() => { navigate("/schoolforms") }}
                                        primaryText={"Forms"}
                                        secondaryText={"Check if there is any new form published by the admin "}
                                        color={"secondary"}
                                        icon={<Menu sx={{ width: "16px" }} />}
                                    />
                                    <HomeButtonComponent
                                        onClickAction={() => { navigate("/responses") }}
                                        primaryText={"Your Form Responses"}
                                        secondaryText={"Checkout your previous form responses"}
                                        color={"secondary"}
                                        icon={<Menu sx={{ width: "16px" }} />}
                                    />
                                </>
                                : null
                        }
                        <HomeButtonComponent
                            onClickAction={LOGGIN_OUT}
                            primaryText={"Logout"}
                            secondaryText={"Log out of your account securely from here."}
                            color={"error"}
                            icon={<LogoutIcon sx={{ width: "16px" }} />}
                        />

                    </Stack>
                </Box>
            </div>
        </div>
    )
}


function HomeButtonComponent({ primaryText, secondaryText, onClickAction, color, icon }) {
    const sx = { height: 'fit-content', minHeight: "64px", display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: '20px' }
    return <Button variant="contained" color={color} onClick={onClickAction} disableElevation sx={sx} fullWidth >
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1} mb={0.3}>
            {icon}
            <Typography variant='body2' textTransform="uppercase"><b>{primaryText}</b></Typography>
        </Stack>

        <Typography variant='caption' textTransform="capitalize" textAlign="left" sx={{ lineHeight: '20px !important' }} color={color == "error" ? "white" : "grey"}>{secondaryText}</Typography>
    </Button>
}