import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, CircularProgress, IconButton, Stack, Typography, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ref as sRef } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { databaseFirestore, storage } from '../../Firebase';
import { BackdropLoader } from '../UniversalFunctions';

export default function AddEvent() {
    const navigate = useNavigate()
    const ImagesUploadLimit = 3
    var imagesUpload = []; // for carring the images to be uploaded.
    var ForImagePreview = []; //For carring the pre urls of the images to be uploaded.
    const imageUrls = []; // For carring the urls of the images after upload
    const [pI, setPI] = useState([]) //Just for holding values, All the links for showing the image into the container before uploading them to firebase. 
    const [uIm, setUIm] = useState([]) // just for holding values, ALl the images to be upload will store in this state. 
    const [eventObj, setEventObj] = useState({
        title: '',
        content: '',
        eventDate: null,
        location: '',
        link: ''
    })
    const [value, setValue] = useState('');
    const [Loading, setLoading] = useState(false)

    // selecting and stoing the images locally
    const handleSubmit = (e) => {
        // Only allowed file
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];

        if (e.target.files.length <= ImagesUploadLimit) {
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = e.target.files[i];

                if (ALLOWED_TYPES.includes(newImage.type) && e.target.files[i].size < 3145728) {
                    imagesUpload.push(newImage);
                    // generating fake/preUrls for images preview on dom before upload
                    ForImagePreview.push({ preURL: global.URL.createObjectURL(newImage) });
                }

                else {
                    alert(`File ${newImage.name} is not supported or bigger than 3 MB`);
                    return
                }
            }
            // console.log("Preview Links", ForImagePreview);
            // console.log("Files For uploading", imagesUpload);
            setPI(ForImagePreview)
            setUIm(imagesUpload)
        }
        else {
            alert(`Sorry :-( You can select maximum ${ImagesUploadLimit} images.`)
        };
    };

    // function for uplading images on the firebase storage
    const uploadImage = async () => {
        if (uIm.length === 0) {
            alert("Please select an image")
            return
        };
        setLoading(true)
        for (let i = 0; i < uIm.length; i++) {
            const newUniqueID = uuidv4()
            const imageRef = sRef(storage, `events/${newUniqueID}`);
            await uploadBytes(imageRef, uIm[i]).then(() => {
                // console.log(`Image ${i} uploaded successfully`);
                getDownloadURL(imageRef).then((url) => {
                    // console.log("Here is the link", url);
                    imageUrls.push({
                        url: url,
                    });
                    // console.log("Img urls length : " + imageUrls.length + ' number of imgs. : ' + uIm.length);
                    if (imageUrls.length === uIm.length) {
                        // console.log("Now i can upload object url");
                        // UploadURL(imageUrls)
                        // ValidateFeilds()
                        // const content = LinksManagement(value)
                        publishBlog()
                    }
                });
            });
        }
    }

    // function for adding the event
    const publishBlog = async () => {
        setLoading(true)
        const myTimestamp = Timestamp.fromDate(new Date());
        const NewId = uuidv4()
        await setDoc(doc(databaseFirestore, "recentEvents", NewId), {
            title: eventObj.title,
            content: eventObj.content,
            imgURLs: imageUrls,
            location: eventObj.location,
            extLink: eventObj.link,
            createdAt: myTimestamp,
            eventDate: Timestamp.fromDate(eventObj.eventDate.toDate())
        }).then(() => {
            // console.log("Blog uploaded successfully, Creating Views Feild......");
            // CreateViewCollection(NewId)
            setLoading(false);
            navigate(-1);
        })
    }

    const ValidateFeilds = () => {
        if (eventObj.title === "") {
            alert("Please enter an event title")
            return
        }
        if (eventObj.content === "") {
            alert("Please enter content for the event")
            return
        }
        if (eventObj.location === "") {
            alert("Please enter location of the event")
            return
        }
        if (eventObj.title === "") {
            alert("Please enter Date of the event")
            return
        }
        uploadImage()
    }

    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Add an Event</Typography>
                        </Stack>
                        {/* <Button variant='contained' color='primary' onClick={() => { navigate("/addevent") }} disableElevation>Add New Event</Button> */}
                    </Stack>
                </Box>

                <Stack p={2} direction="column" spacing={2}>
                    <TextField fullWidth label="Title of the Event" value={eventObj.title} required onChange={(e) => { setEventObj({ ...eventObj, title: e.target.value }) }} />
                    <TextField fullWidth label="Description of the Event" multiline rows={6} required value={eventObj.content} onChange={(e) => { setEventObj({ ...eventObj, content: e.target.value }) }} />
                    <TextField fullWidth label="Location of the Event" value={eventObj.location} required onChange={(e) => { setEventObj({ ...eventObj, location: e.target.value }) }} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker value={eventObj.eventDate} label="Date of the Event" onChange={(newValue) => { setEventObj({ ...eventObj, eventDate: newValue }) }} />
                    </LocalizationProvider>
                    <TextField fullWidth label="Any link related to event" value={eventObj.link} onChange={(e) => { setEventObj({ ...eventObj, link: e.target.value }) }} />

                    <label htmlFor="fileUploadEvent" className="image-upload">
                        {
                            pI.length === 0 ? <div className="beforeUpload">
                                {/* <img src={upload} alt="icon" /> */}
                                <Typography variant='body2'> Add Event Image (max 3 allowed)</Typography>
                            </div> : <div id="imgPreview">
                                {pI.map((key) => (<img src={key.preURL} />))}
                            </div>
                        }
                        <div id="Loader" style={{ display: "none" }}>
                            <CircularProgress />
                        </div>
                    </label>
                    <input type="file" id="fileUploadEvent" multiple className="forHide" onChange={handleSubmit} />

                    <Button variant='contained' disabled={Loading} disableElevation onClick={ValidateFeilds}>{Loading ? <CircularProgress color='secondary' size={24} /> : "Add this event"}</Button>
                </Stack>
            </div>
        </div>
    )
}
