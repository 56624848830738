import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, CircularProgress, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { databaseFirestore } from '../../Firebase';

const BlogCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newCategory, setNewCategory] = useState('');

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'BlogCategories', 'categories');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setCategories(docSnap.data().categories || []);
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change for new category
    const handleNewCategoryChange = (e) => {
        setNewCategory(e.target.value);
    };

    // Add new category
    const handleAddCategory = () => {
        if (newCategory.trim() === '') {
            alert('Please enter a category name');
            return;
        }
        setCategories([...categories, newCategory]);
        setNewCategory('');
    };

    // Delete category
    const handleDeleteCategory = (index) => {
        const updatedCategories = categories.filter((_, i) => i !== index);
        setCategories(updatedCategories);
    };

    // Save updated categories to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'BlogCategories', 'categories');
        await setDoc(docRef, { categories });
        setLoading(false);
        alert('Categories updated successfully!');
    };

    if (loading) {
        return <CircularProgress className='absCenter'/>;
    }

    return (
        <Box>
            {categories.map((category, index) => (
                <Box key={index} display="flex" alignItems="center" marginBottom="8px">
                    <TextField
                        fullWidth
                        value={category}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton onClick={() => handleDeleteCategory(index)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" alignItems="center" marginBottom="16px">
                <TextField
                    label="New Category"
                    fullWidth
                    value={newCategory}
                    onChange={handleNewCategoryChange}
                />
                <IconButton onClick={handleAddCategory} color='primary'>
                    <AddIcon />
                </IconButton>
            </Box>
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
};

export default BlogCategories;
