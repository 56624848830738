import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { databaseFirestore } from '../../Firebase';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { ArrowBack, Edit } from '@mui/icons-material';
import { BackdropLoader } from '../UniversalFunctions';

export default function SchoolUserForms({ userData }) {

    const FormsArray = userData ? [...userData.anyNewForm].reverse() : []
    const navigate = useNavigate()
    const numberOfResultsinEachTime = 10
    const [Forms, setForms] = useState([])
    const [Loading, setLoading] = useState(false)

    // function for fetching the forms
    async function FetchForms(arrIds) {
        const formPromises = arrIds.map(async (id) => {
            setLoading(true)
            const docRef = doc(databaseFirestore, 'forms', id)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                return {
                    data: docSnap.data(),
                    id: docSnap.id
                }
            } else { return null }
        })
        const formData = await Promise.all(formPromises)

        setForms((prev) => {
            let filteredFormdata = formData.filter(form => form !== null)
            let prevForms = [...prev]
            let newArr = prevForms.concat(filteredFormdata)
            return newArr
        })
        setLoading(false)
    }

    // function for fetching the next forms on clicking on fetch more button 
    useEffect(() => {
        if (Forms.length > 0) { return }
        if (FormsArray.length < numberOfResultsinEachTime) { FetchForms(FormsArray) }
        else { FetchForms(FormsArray.splice(0, numberOfResultsinEachTime)) }
    }, [])

    // function for fetching more forms  | Pagination 
    function FetchMore() {
        if ((Forms.length + numberOfResultsinEachTime) > FormsArray.length) {
            FetchForms(FormsArray.splice(Forms.length))
        }
        else { FetchForms(FormsArray.splice(Forms.length, numberOfResultsinEachTime)) }
    }

    return <div className="appContainer">
        {Loading ? <BackdropLoader /> : ""}
        <div className="appWrapper">
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => { navigate('/') }}>
                            <ArrowBack color='primary' fontSize='small' />
                        </IconButton>
                        <Typography variant='h6' color="primary" textTransform="uppercase">New Forms</Typography>
                    </Stack>
                </Stack>
                <br />
                {
                    Forms !== undefined && Forms !== null ?
                        Forms.map((form, index) => {
                            return <FormTile key={index} srNo={index + 1} id={form.id} heading={form.data?.formTitle} date={form.data?.createdAt} />
                        })
                        : ""
                }
                {Forms.length !== FormsArray.length && Forms.length > 0 ? <Button variant='outlined' fullWidth color='primary' onClick={FetchMore} disableElevation>Load more</Button> : ''}
            </Box>
        </div>
    </div>
}


function FormTile(props) {
    const navigate = useNavigate()
    return <Box p={2}>
        <Stack direction={'row'} justifyContent='space-between' >
            <Box width={'72%'}>
                <Typography variant='body2'>{props.heading}</Typography>
                <Typography variant='caption' color="grey">Published on : {props.date?.toDate()?.toString()?.slice(0, 21)}</Typography>
            </Box>
            <Button
                variant='contained'
                color='primary'
                startIcon={<Edit fontSize='small' />}
                disableElevation
                onClick={() => { navigate(`/fillform?formid=${props.id}`) }}
            > Fill Form</Button>
        </Stack>
        <br />
        <Divider />
    </Box>
}