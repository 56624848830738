import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, CircularProgress, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { databaseFirestore } from '../../Firebase';

const NoticeTypes = () => {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newType, setNewType] = useState('');

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'about', 'noticeTypes');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setTypes(docSnap.data().types || []);
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change for new type
    const handleNewTypeChange = (e) => {
        setNewType(e.target.value);
    };

    // Add new type
    const handleAddType = () => {
        if (newType.trim() === '') {
            alert('Please enter a notice type');
            return;
        }
        setTypes([...types, newType]);
        setNewType('');
    };

    // Delete type
    const handleDeleteType = (index) => {
        const updatedTypes = types.filter((_, i) => i !== index);
        setTypes(updatedTypes);
    };

    // Save updated types to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'about', 'noticeTypes');
        await setDoc(docRef, { types });
        setLoading(false);
        alert('Notice types updated successfully!');
    };

    if (loading) {
        return <CircularProgress className='absCenter' />;
    }

    return (
        <Box>
            {types.map((type, index) => (
                <Box key={index} display="flex" alignItems="center" marginBottom="8px">
                    <TextField
                        fullWidth
                        value={type}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton onClick={() => handleDeleteType(index)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" alignItems="center" marginBottom="16px">
                <TextField
                    label="New Notice Type"
                    fullWidth
                    value={newType}
                    onChange={handleNewTypeChange}
                />
                <IconButton onClick={handleAddType} color="primary">
                    <AddIcon />
                </IconButton>
            </Box>
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
};

export default NoticeTypes;
