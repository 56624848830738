import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { databaseFirestore } from '../../Firebase';

const AddClass = () => {
    const [className, setClassName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleAddClass = async () => {
        if (className.trim() === '') {
            setError('Please enter a class name.');
            return;
        }

        setLoading(true);
        try {
            const docRef = await addDoc(collection(databaseFirestore, 'syllabus'), {
                className: className,
                createdAt: serverTimestamp()
            });
            console.log('Class added with ID: ', docRef.id);

            // Redirect to syllabus page after adding class
            navigate('/syllabus');
        } catch (error) {
            console.error('Error adding class: ', error);
            setError('Failed to add class. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Typography variant='h6' color="primary" textTransform="uppercase">Add Class</Typography>

                    <Box mt={2}>
                        <TextField
                            label="Class Name"
                            fullWidth
                            value={className}
                            onChange={(e) => setClassName(e.target.value)}
                            error={error !== ''}
                            helperText={error}
                            margin="normal"
                        />
                    </Box>

                    <Box mt={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleAddClass}
                            disableElevation
                            disabled={loading}
                        >
                            {loading ? 'Adding...' : 'Add Class'}
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default AddClass;
