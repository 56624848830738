import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, deleteDoc, doc, getDocs, setDoc, orderBy, query, limit, startAfter } from 'firebase/firestore';
import Delete from '@mui/icons-material/Delete';
import { BackdropLoader } from '../UniversalFunctions';

export default function Eventhome() {
    const navigate = useNavigate()
    const [Data, setData] = useState([])
    const [lastDoc, setLastDoc] = useState(null);
    const [isListOver, setisListOver] = useState(false)
    const [isError, setIsError] = useState(false)
    const [Loading, setLoading] = useState(false)

    // console.log(Data);


    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true)
            const initialData = await FetchData();
            setData(initialData);
            setLoading(false)
        };
        if (Data.length <= 0 && !isError) { fetchInitialData(); }
    }, []);

    const FetchData = async () => {
        try {
            const dataref = collection(databaseFirestore, 'recentEvents');
            // query(dataref, orderBy("createdAt", "desc"))
            const queryRef = lastDoc !== null ? query(dataref, orderBy("eventDate", "desc"), startAfter(lastDoc), limit(10)) : query(dataref, orderBy("eventDate", "desc"), limit(10))
            const querySnapshot = await getDocs(queryRef);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // console.log(data);
                fetchedData.push({
                    data: data,
                    id: doc.id
                });
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            if (lastVisible === undefined) { setisListOver(true) }
            setLastDoc(lastVisible);
            return fetchedData;
        } catch (error) {
            console.error('Error:', error);
            setIsError(true)
            return [];
        }
    };


    const loadMoreData = async () => {
        try {
            setLoading(true)
            const newData = await FetchData();
            setData((prevData) => [...prevData, ...newData]);
            setLoading(false)
        } catch (error) {
            console.error('Error loading more data:', error);
            setLoading(false)
        }
    };
    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate('/') }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">All Events</Typography>
                        </Stack>
                        <Button variant='contained' color='primary' onClick={() => { navigate("/addevent") }} disableElevation>Add New Event</Button>
                    </Stack>
                    <br />

                    <Stack>
                        {
                            Data !== undefined && Data !== null ?
                                Data.map((event, index) => {
                                    return <BlogTile key={index} srNo={index + 1} id={event?.id} data={event?.data} />
                                })
                                : ""
                        }
                        {/* <BlogTile /> */}
                        {/* <BlogTile /> */}
                        {/* <BlogTile /> */}

                    </Stack>
                    {!isListOver ? <Button variant='outlined' fullWidth color='primary' onClick={loadMoreData} disableElevation>load more</Button> : ''}
                </Box>
            </div>
        </div>
    )
}



function BlogTile({ srNo, id, data }) {
    const navigate = useNavigate()

    // function for deleting the image
    const deleteHof = async () => {
        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this Event?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            await deleteDoc(doc(databaseFirestore, 'recentEvents', id)).then((res) => {
                navigate(0)
            })
            // console.log('deleted from Firestore successfully.');
        }
        catch (error) {
            console.error('Error deleting from Firestore:', error);
        }
    }
    // console.log(data);
    return <Box p={2}>
        <Stack direction={'row'} >
            <Box width={'40px'}>
                <Typography variant='body2'>{srNo}</Typography>
            </Box>
            <Box width={'100%'}>
                <Typography variant='body2'><b>Event Title : </b>{data?.title}</Typography>
                <Typography variant='caption' color="grey"><b>Event Date</b> : {data?.eventDate?.toDate()?.toString()?.slice(0, 21)}</Typography><br />
                <Typography variant='caption' color="grey"><b>Event Location</b> : {data?.location}</Typography>

            </Box>
            <Box>
                <IconButton onClick={deleteHof}>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Stack>
        <br />
        <Divider />
    </Box>
}