import React, { useState } from 'react';
import { Box, Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { databaseFirestore } from '../../Firebase';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const schoolCities = [
    "Pali", "Bali", "Sirohi", "Jalore", "Balotra ", "Barmer",
    "Jaisalmer", "Jodhpur Jila", "Jodhpur Mahanagar", "Nagaur",
    "Bikaner", "Sri Ganganagar", "Hanumangarh"
];

const AddSchool = () => {
    const [name, setName] = useState('');
    const [level, setLevel] = useState('');
    const [address, setAddress] = useState('');
    const [principleName, setPrincipleName] = useState('');
    const [city, setCity] = useState('');
    const navigate = useNavigate()

    const handleAddSchool = async () => {
        if (!name || !level || !address || !principleName || !city) {
            alert('Please fill in all fields');
            return;
        }

        const newSchool = {
            name,
            level,
            address,
            principleName,
            imgURL: "",
            addedOn: serverTimestamp(),
            city
        };

        try {
            await addDoc(collection(databaseFirestore, 'schools'), newSchool);
            alert('School added successfully');
            setName('');
            setLevel('');
            setAddress('');
            setPrincipleName('');
            setCity('');
        } catch (error) {
            // console.error('Error adding school: ', error);
            alert('Error adding school');
        }
    };

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Add New School</Typography>
                        </Stack>
                        {/* <Button variant='contained' color='primary' onClick={() => { navigate("/addnotice") }} disableElevation>Publish New notice</Button> */}
                    </Stack>
                    <Box>
                        <TextField
                            label="विद्यामंदिर का नाम"
                            fullWidth
                            margin="normal"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            label="विद्यालय का स्तर"
                            fullWidth
                            margin="normal"
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                        />
                        <TextField
                            label="पता"
                            fullWidth
                            margin="normal"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <TextField
                            label="प्रधानाचार्य का नाम"
                            fullWidth
                            margin="normal"
                            value={principleName}
                            onChange={(e) => setPrincipleName(e.target.value)}
                        />
                        <TextField
                            label="शहर"
                            fullWidth
                            margin="normal"
                            select
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        >
                            {schoolCities.map((city) => (
                                <MenuItem key={city} value={city}>
                                    {city}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button variant="contained" color="primary" onClick={handleAddSchool}>
                            Add School
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>




    );
};

export default AddSchool;
