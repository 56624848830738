import React, { useState } from 'react'
import { CreateUser, CreatingNewUser } from '../Forms/FormDatabaseFunctions'
import { PageWrapper } from '../UniversalFunctions'
import { Button, Stack, TextField } from '@mui/material'

export default function AddUser() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    uid: ''
  })
  return <PageWrapper title={'Add New user'} backToHome={true}>
    <br />
    <Stack gap={1}>
      <TextField type="text" placeholder='Name' value={userData.name} onChange={(e) => { setUserData({ ...userData, name: e.target.value }) }} />
      <TextField type="text" placeholder='Email' value={userData.email} onChange={(e) => { setUserData({ ...userData, email: e.target.value }) }} />
      <TextField type="text" placeholder='UID' value={userData.uid} onChange={(e) => { setUserData({ ...userData, uid: e.target.value }) }} />
      <Button variant='contained' onClick={() => { CreateUser(userData.uid, userData.email, userData.name) }}>Add This USer</Button>
      {/* <Button variant='contained' onClick={() => { CreatingNewUser(userData.email, userData.name) }}>Create new user</Button> */}
    </Stack>
  </PageWrapper>
}