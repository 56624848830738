import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, CircularProgress, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { databaseFirestore } from '../../Firebase';

const ContactDetails = () => {
    const [contactDetails, setContactDetails] = useState({
        address: '',
        email: '',
        phones: [],
        whatsAppNo: ''
    });
    const [newPhone, setNewPhone] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'about', 'contact');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setContactDetails(docSnap.data() || {});
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change for contact details
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactDetails({ ...contactDetails, [name]: value });
    };

    // Handle input change for new phone number
    const handleNewPhoneChange = (e) => {
        setNewPhone(e.target.value);
    };

    // Add new phone number
    const handleAddPhone = () => {
        if (newPhone.trim() === '') {
            alert('Please enter a phone number');
            return;
        }
        setContactDetails({
            ...contactDetails,
            phones: [...contactDetails.phones, newPhone]
        });
        setNewPhone('');
    };

    // Delete phone number
    const handleDeletePhone = (index) => {
        const updatedPhones = contactDetails.phones.filter((_, i) => i !== index);
        setContactDetails({ ...contactDetails, phones: updatedPhones });
    };

    // Save updated contact details to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'about', 'contact');
        await setDoc(docRef, contactDetails);
        setLoading(false);
        alert('Contact details updated successfully!');
    };

    if (loading) {
        return <CircularProgress className='absCenter'/>;
    }

    return (
        <Box>
            <TextField
                label="Address"
                name="address"
                fullWidth
                value={contactDetails.address}
                onChange={handleInputChange}
                margin="normal"
            />
            <TextField
                label="Email"
                name="email"
                fullWidth
                value={contactDetails.email}
                onChange={handleInputChange}
                margin="normal"
            />
            <TextField
                label="WhatsApp Number"
                name="whatsAppNo"
                fullWidth
                value={contactDetails.whatsAppNo}
                onChange={handleInputChange}
                margin="normal"
            />
            <Box marginBottom="16px" mt={2}>
                <Typography variant='body1' gutterBottom>All Phone Numbers</Typography>
                {contactDetails.phones.map((phone, index) => (
                    <Box key={index} display="flex" alignItems="center" marginBottom="8px">
                        <TextField
                            fullWidth
                            value={phone}
                            disabled
                        />
                        <IconButton onClick={() => handleDeletePhone(index)} color="error">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}
                <Box display="flex" alignItems="center">
                    <TextField
                        label="New Phone Number"
                        fullWidth
                        value={newPhone}
                        onChange={handleNewPhoneChange}
                    />
                    <IconButton onClick={handleAddPhone} color="primary">
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
};

export default ContactDetails;
