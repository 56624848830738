import React, { useEffect, useState, useCallback } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Modal,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import _ from 'lodash';
import { databaseFirestore } from '../../Firebase';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const SchoolList = () => {
    const [schools, setSchools] = useState([]);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSchools = async () => {
            const querySnapshot = await getDocs(collection(databaseFirestore, 'schools'));
            const schoolsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setSchools(schoolsData);
            setFilteredSchools(schoolsData);
            setLoading(false);
        };

        fetchSchools();
    }, []);

    const handleSearch = useCallback(
        _.debounce((query) => {
            const lowercasedQuery = query.toLowerCase();
            const filtered = schools.filter(school =>
                (school.name && school.name.toLowerCase().includes(lowercasedQuery)) ||
                (school.address && school.address.toLowerCase().includes(lowercasedQuery)) ||
                (school.city && school.city.toLowerCase().includes(lowercasedQuery)) ||
                (school.principleName && school.principleName.toLowerCase().includes(lowercasedQuery))
            );
            setFilteredSchools(filtered);
        }, 1000), [schools]
    );

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        handleSearch(query);
    };

    const handleEditOpen = (school) => {
        setSelectedSchool(school);
        setOpenEditModal(true);
    };

    const handleEditClose = () => {
        setOpenEditModal(false);
        setSelectedSchool(null);
    };

    const handleDeleteOpen = (school) => {
        setSelectedSchool(school);
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
        setSelectedSchool(null);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedSchool({ ...selectedSchool, [name]: value });
    };

    const handleEditSave = async () => {
        if (selectedSchool) {
            const docRef = doc(databaseFirestore, 'schools', selectedSchool.id);
            await updateDoc(docRef, selectedSchool);
            const updatedSchools = schools.map(school =>
                school.id === selectedSchool.id ? selectedSchool : school
            );
            setSchools(updatedSchools);
            setFilteredSchools(updatedSchools);
            handleEditClose();
        }
    };

    const handleDeleteConfirm = async () => {
        if (selectedSchool) {
            const docRef = doc(databaseFirestore, 'schools', selectedSchool.id);
            await deleteDoc(docRef);
            const updatedSchools = schools.filter(school => school.id !== selectedSchool.id);
            setSchools(updatedSchools);
            setFilteredSchools(updatedSchools);
            handleDeleteClose();
        }
    };

    const columns = [
        { field: 'name', headerName: 'School Name', flex: 1 },
        // { field: 'address', headerName: 'Address', flex: 1 },
        // { field: 'principleName', headerName: 'Principal Name', flex: 1 },
        { field: 'city', headerName: 'City', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleEditOpen(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteOpen(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        }
    ];

    if (loading) {
        return <CircularProgress className='absCenter' />;
    }

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate('/') }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">All Schools</Typography>
                        </Stack>
                        <Button variant='contained' color='primary' onClick={() => { navigate("/addnewschool") }} disableElevation>Add New School</Button>
                    </Stack>

                    <Box>
                        <TextField
                            label="Search"
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                            margin="normal"
                        />
                        <Box>
                            <DataGrid
                                rows={filteredSchools}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                disableSelectionOnClick
                                sx={{ height: "75vh" }}
                            />
                        </Box>

                        <Modal open={openEditModal} onClose={handleEditClose}>
                            <Box sx={{ ...modalStyle, width: 400 }}>
                                <Typography variant="h6" gutterBottom>Edit School</Typography>
                                {selectedSchool && (
                                    <Box>
                                        <TextField
                                            label="School Name"
                                            fullWidth
                                            name="name"
                                            value={selectedSchool.name}
                                            onChange={handleEditChange}
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Address"
                                            fullWidth
                                            name="address"
                                            value={selectedSchool.address}
                                            onChange={handleEditChange}
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Principal Name"
                                            fullWidth
                                            name="principleName"
                                            value={selectedSchool.principleName}
                                            onChange={handleEditChange}
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Level"
                                            fullWidth
                                            name="level"
                                            value={selectedSchool.level}
                                            onChange={handleEditChange}
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Phones"
                                            fullWidth
                                            name="phones"
                                            value={selectedSchool.phones}
                                            onChange={handleEditChange}
                                            margin="normal"
                                        />
                                        <Button variant="contained" color="primary" fullWidth size='large' onClick={handleEditSave} disableElevation>
                                            Save
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Modal>

                        <Dialog
                            open={openDeleteDialog}
                            onClose={handleDeleteClose}
                        >
                            <DialogTitle>Delete School</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this school?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDeleteConfirm} color="primary" variant='contained' disableElevation>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
};

export default SchoolList;