import { createTheme } from "@mui/material";

export const theme = createTheme({
    // this palette of the colors fix the colors for the elements of the material UI. For giving our own colors to material ui components, we just have to give color="PrimaryBlue" and it will reflect to component. PrimaryBlue is our custom color. Similarly we can define more colors for our personal use
    palette: {
        primary: {
            main: "#EA8F07",
            contrastText: "#fff",
        },
        secondary: {
            main: "#FDF4E6",
            contrastText: "#EA8F07",
        },
        error: {
            main: "#FF4444",
            contrastText: "#ffffff",
        },
    },

    typography: {
        fontFamily: "poppins",
        body2: {
            fontSize: "16px",
            fontWeight: 400,
        }
    },

    //Here we overrides some properties of the MUI components.
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    fontWeight: "600",
                    height: "40px",
                    boxShadow: 'none',
                    fontWeight: 400,
                    '&:hover': {
                        backgroundColor: '#FFEED4',
                        color: '#EA8F07'
                    },
                },
            },
        },
    }
});
