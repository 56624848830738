import React, { useState } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Select, FormControl, InputLabel, List, ListItem, IconButton, ListItemSecondaryAction, Grid, Paper, ListItemText, Chip, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add, ArrowBack, AttachFile, Save, Send } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { AddForm } from './FormDatabaseFunctions';
import { BackdropLoader } from '../UniversalFunctions';

const CreateForm = () => {
    const [fields, setFields] = useState([]);
    const [fieldType, setFieldType] = useState('');
    const [fieldTitle, setFieldTitle] = useState('');
    const [fieldOptions, setFieldOptions] = useState([]);
    const [newOption, setNewOption] = useState('');
    const [showFieldForm, setShowFieldForm] = useState(false);
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)
    const [formTitle, setformTitle] = useState('')


    // console.log(fields);

    const handleAddField = () => {
        setShowFieldForm(true);
    };

    const handleSaveField = () => {

        // validating the title and the type field 
        if (fieldType === '') {
            alert('Please select a field type')
            return
        }
        if (fieldTitle === '') {
            alert("Please select a field title")
            return
        }
        if (fieldType === types.SELECTION && fieldOptions.length < 2) {
            alert('Please add at least 2 options in the selection field')
            return
        }
        const newField = {
            fieldID: `${fieldType}${fields.length + 1}`,
            fieldTitle,
            fieldType,
            options: fieldType === types.SELECTION ? fieldOptions : [],
        };

        // setting form field in the state
        setFields([...fields, newField]);
        setShowFieldForm(false);

        // resetting values
        setFieldType('');
        setFieldTitle('');
        setFieldOptions([]);
        setNewOption('');
    };

    // function for adding the options in the field type - selection 
    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            setFieldOptions([...fieldOptions, newOption.trim()]);
            setNewOption('');
        }
    };

    // function for removing an option while adding the options in the field type - selection 
    const handleRemoveOption = (index) => {
        const updatedOptions = fieldOptions.filter((_, i) => i !== index);
        setFieldOptions(updatedOptions);
    };

    // function for deleting the field
    const handleDeleteField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ''}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => { navigate(-1) }}>
                            <ArrowBack color='primary' fontSize='small' />
                        </IconButton>
                        <Typography variant='h6' color="primary" textTransform="uppercase">Change App Banner </Typography>
                    </Stack>
                    <br />
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <TextField label="Title of the form" value={formTitle} onChange={(e) => { setformTitle(e.target.value) }} fullWidth placeholder='Enter title' size='small' />
                        <Button variant="contained" onClick={() => { AddForm(fields, setLoading, formTitle, navigate) }} sx={{ textWrap: 'nowrap', width: '300px' }} color="primary" disabled={fields.length === 0} startIcon={<Send fontSize='small' />} disableElevation>
                            Publish this form
                        </Button>
                    </Stack>
                    <br />
                    <Button variant="contained" color="error" startIcon={<Add fontSize='small' />} onClick={handleAddField} disableElevation>
                        Add a field
                    </Button>
                    &nbsp;



                    {/* <Typography variant='caption'> Add fields to your form by clicking to this button</Typography> */}


                    {showFieldForm && (
                        <Box mt={2} bgcolor='#f5f5f5' p={2} borderRadius={2} >
                            <FormControl fullWidth>
                                <InputLabel id="field-type-label">Choose the type of the field</InputLabel>
                                <Select
                                    labelId="field-type-label"
                                    value={fieldType}
                                    onChange={(e) => setFieldType(e.target.value)}
                                >
                                    <MenuItem value="text">
                                        Text (takes simple one or two line text from the user)
                                    </MenuItem>
                                    <MenuItem value="content">
                                        Content (takes big paragraphs from the user)
                                    </MenuItem>
                                    <MenuItem value="selection">
                                        Selection (allows user to choose an option from the given options)
                                    </MenuItem>
                                    <MenuItem value="file">
                                        Attach File (allows user to attach a file in the form)
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Field Title"
                                value={fieldTitle}
                                onChange={(e) => setFieldTitle(e.target.value)}
                                margin="normal"
                            />
                            {fieldType === 'selection' && (
                                <Box mt={2}>
                                    <Typography variant='caption'>Add options for the Selection field</Typography>
                                    <TextField
                                        fullWidth
                                        label="Add Option"
                                        size='small'
                                        value={newOption}
                                        onChange={(e) => setNewOption(e.target.value)}
                                    />
                                    <br />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleAddOption}
                                        startIcon={<AddIcon />}
                                        sx={{ marginTop: 1 }}
                                    >
                                        Add Option
                                    </Button>
                                    <List>
                                        {fieldOptions.map((option, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={option} />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => handleRemoveOption(index)} size='small' color='error'>
                                                        <RemoveIcon fontSize='small' />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}
                            <Button variant="contained" disableElevation fullWidth startIcon={<Save fontSize='small' />} color="primary" onClick={handleSaveField}>
                                Save Field
                            </Button>
                        </Box>
                    )}
                    <Box mt={4}>
                        <Typography variant="h6" mb={2}>Added Fields</Typography>
                        {fields.length <= 0 && <Typography variant='body2' color='grey'>No fields added</Typography>}
                        <Box>
                            <Grid container spacing={2}>
                                {fields.map((field, index) => (
                                    <Grid item xs={12} key={field.fieldID}>
                                        <Paper elevation={1} style={{ padding: '16px', position: 'relative', borderRadius: 2 }}>
                                            <Typography variant="body1" width='96%'>
                                                <Chip label={index + 1} variant='filled' size='small' /> &nbsp;{field.fieldTitle}
                                            </Typography>

                                            <Typography variant="subtitle2" color='grey' mt={1}>
                                                Field Type: {field.fieldType}
                                            </Typography>

                                            {field.fieldType === types.SELECTION ? <Typography variant="subtitle2" color='grey'>
                                                Options : {field.options ? field.options.map((option, index) => <span key={index}>{option}{index === field.options.length - 1 ? "" : ", "}</span>) : ""}
                                            </Typography> : ''}

                                            <RenderDisabledFields fieldTitle={field?.fieldTitle} fieldType={field?.fieldType} options={field?.options} />
                                            <IconButton
                                                edge="end"
                                                style={{ position: 'absolute', top: '16px', right: '16px' }}
                                                onClick={() => handleDeleteField(index)}
                                                size='small' color='error'
                                            >
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default CreateForm;

export const types = {
    TEXT: 'text',
    CONTENT: 'content',
    SELECTION: 'selection',
    FILE: 'file',
}

export function RenderDisabledFields({ fieldTitle, fieldType, options, value }) {
    return <div>
        {fieldType === 'text' && (value ? <Typography variant='body2' color='grey'><b>Answer : </b>{value}</Typography> : <TextField fullWidth disabled value={fieldTitle} margin="normal" />)}
        {fieldType === 'content' && (value ? <Typography variant='body2' color='grey'><b>Answer : </b>{value}</Typography> : <TextField fullWidth disabled value={value ? value : fieldTitle} multiline rows={10} margin="normal" />)}
        {fieldType === 'selection' && (
            <FormControl fullWidth disabled margin="normal">
                <InputLabel>{fieldTitle}</InputLabel>
                <Select value={value ? value : ''}>
                    {options ? options?.map((option, idx) => (
                        <MenuItem key={idx} value={option}> {option}</MenuItem>
                    )) : ''}
                </Select>
            </FormControl>
        )}
        {fieldType === 'file' && (
            <Box display="flex" alignItems="center" margin="normal" mt={1}>
                {
                    value ? <Typography variant='body2' noWrap>Attached File Link : <a href={value} target='_blank'>{value}</a></Typography>
                        : <Button variant="outlined" disabled startIcon={<AttachFile fontSize='small' />}> Attach File</Button>
                }
            </Box>
        )}
    </div>
}


