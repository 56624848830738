import ArrowBack from '@mui/icons-material/ArrowBack'
import Delete from '@mui/icons-material/Delete'
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, deleteDoc, doc, getDocs, setDoc, orderBy, query, limit, startAfter } from 'firebase/firestore';
import { BackdropLoader } from '../UniversalFunctions'

export default function HOFhome() {

    const navigate = useNavigate()
    const [Data, setData] = useState(null)
    const [lastDoc, setLastDoc] = useState(null);
    const [isListOver, setisListOver] = useState(false)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true)
            const initialData = await fetchImageURLs();
            setData(initialData);
            setLoading(false)
        };
        fetchInitialData();
    }, []);

    const fetchImageURLs = async () => {
        try {
            const dataref = collection(databaseFirestore, 'hallOfFame');
            // query(dataref, orderBy("createdAt", "desc"))
            const queryRef = lastDoc !== null ? query(dataref, orderBy("createdAt", "desc"), startAfter(lastDoc), limit(10)) : query(dataref, orderBy("createdAt", "desc"), limit(10))
            const querySnapshot = await getDocs(queryRef);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                fetchedData.push({
                    data: data,
                    id: doc.id
                });
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            if (lastVisible === undefined) { setisListOver(true) }
            setLastDoc(lastVisible);
            return fetchedData;
        } catch (error) {
            console.error('Error fetching image URLs from Firestore:', error);
            return [];
        }
    };


    const loadMoreData = async () => {
        try {
            const newData = await fetchImageURLs();
            setData((prevData) => [...prevData, ...newData]);
        } catch (error) {
            console.error('Error loading more data:', error);
        }
    };


    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate('/') }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Hall of fames </Typography>
                        </Stack>
                        <Button variant='contained' color='primary' onClick={() => { navigate("/addhof") }} disableElevation>Add New</Button>
                    </Stack>
                    <br />
                    <Typography variant='subtitle1' mb={2} >Hall of Fame </Typography>
                    <Stack>
                        {
                            Data !== undefined && Data !== null ?
                                Data.map((hof, index) => {
                                    return <HallOfFame key={index} data={hof.data} id={hof.id} />
                                })
                                : ""
                        }

                    </Stack>
                    {!isListOver ? <Button variant='outlined' fullWidth color='primary' onClick={loadMoreData} disableElevation>load more</Button> : ''}
                </Box>
            </div>
        </div>
    )
}


function HallOfFame(props) {
    // console.log(props.id);
    const navigate = useNavigate()

    // function for deleting the image
    const deleteHof = async () => {
        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this Hall Of Fame?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            await deleteDoc(doc(databaseFirestore, 'hallOfFame', props.id)).then((res) => {
                navigate(0)
            })
            console.log('deleted from Firestore successfully.');
        }
        catch (error) {
            console.error('Error deleting from Firestore:', error);
        }
    }



    return <Box width={'100%'} p={2} position="relative">
        <Stack direction="row" gap={2}>

            <Box width="150px" height="150px" bgcolor="lightgrey" minWidth={150} borderRadius={'12px'} overflow="hidden">
                <img src={props.data?.imgURL} alt="hof image" width={150} height={150} style={{ objectFit: 'cover' }} />
            </Box>

            <Stack direction="column" justifyContent="space-between">
                <Box>
                    <Typography variant='h6'>{props.data?.name}</Typography>
                    <Typography variant='body2'>{props.data?.post}</Typography>
                </Box>
                <Typography variant='body2'>{props.data?.school}</Typography>
            </Stack>
        </Stack>

        {/* content */}
        <Typography variant='body2' mt={2} mb={2}>{props.data?.content}</Typography>

        <IconButton sx={{ position: "absolute", top: "10px", right: "10px" }} onClick={deleteHof}>
            <Delete fontSize='small' />
        </IconButton>
        <Divider />
    </Box>
}