import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, IconButton, Stack, Typography, TextField, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router'
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';

export default function AddImage() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [imgURL, setimgURL] = useState('')
    const [imageToUpload, setImageToUpload] = useState(null)
    const [imgDetails, setImgDetails] = useState({
        title: '',
        content: ''
    })

    // console.log(imageToUpload);

    // function for hading the image upload
    const handleChangeImage = (e) => {
        const selectedImage = e.target.files[0];

        // if no image is selected then eat 5 star do nothing 
        if (!selectedImage) return;

        // Check if the selected file type is allowed
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedTypes.includes(selectedImage.type)) {
            alert('Only PNG, JPEG, and JPG files are allowed.');
            return;
        }

        // Check if the selected file size is less than 4MB
        const maxSize = 3 * 1024 * 1024; // 4MB in bytes
        if (selectedImage.size > maxSize) {
            alert('Image size must be less than 3MB.');
            return;
        }
        setimgURL(URL.createObjectURL(selectedImage));
        setImageToUpload(selectedImage)
    };


    const uploadImage = async () => {

        if (imgDetails.title === "") {
            alert("Please fill all the details")
            return
        }

        if (imageToUpload === null) {
            alert("Please select an image")
            return
        }

        // Upload the selected image to Firebase Storage
        const imageRef = ref(storage, `gallery/${imageToUpload.name}`);
        setLoading(true);
        await uploadBytes(imageRef, imageToUpload);

        // Get the download URL of the uploaded image
        const downloadURL = await getDownloadURL(imageRef);
        setimgURL(downloadURL);

        // Inside handleChangeImage function after obtaining imageURL
        storeImageURLInFirestore(downloadURL);
    }


    const storeImageURLInFirestore = async (imageURL) => {
        try {
            const myTimestamp = Timestamp.fromDate(new Date());
            await addDoc(collection(databaseFirestore, 'gallery'), {
                imgURL: imageURL,
                imgAdded: myTimestamp,
                title: imgDetails.title,
                content: imgDetails.content
                // other feilds
            })
            // await setDoc(docRef, { imgURL: imageURL, imgAdded: myTimestamp });
            navigate("/gallery")
            setLoading(false);
        } catch (error) { console.error('Error storing image URL in Firestore:', error) }
    };
    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Stack direction={'row'} alignItems={'center'}>
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Add Images</Typography>
                        </Stack>
                        {/* <Button variant='contained' color='primary' onClick={() => { navigate("/addimages") }} disableElevation>Add Images</Button> */}
                    </Stack>
                    <br />


                    <div className="container" onClick={() => document.getElementById(`input`).click()}>
                        <Box width='100%' height={150} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#D9D9D9'} borderRadius={4} overflow={'hidden'}>
                            {loading ? (
                                <CircularProgress />
                            ) : imgURL ? (
                                <img src={imgURL} alt={`Image`} width={'100%'} />
                            ) : (
                                <p>Upload an image</p>
                            )}
                            <input
                                id={`input`}
                                type="file"
                                onChange={handleChangeImage}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        <br /> 
                    </div>

                    <TextField label="Title of the image" fullWidth value={imgDetails.title} onChange={(e) => { setImgDetails({ ...imgDetails, title: e.target.value }) }} />
                    &nbsp;
                    <TextField label="Content (Optional)" value={imgDetails.content} fullWidth onChange={(e) => { setImgDetails({ ...imgDetails, content: e.target.value }) }} />
                    &nbsp;
                    <Button disabled={loading} variant='contained' onClick={uploadImage} fullWidth disableElevation> Upload Image</Button>
                </Box>
            </div>
        </div>
    )
}
