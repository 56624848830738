import { StyledBox } from './AboutBase'
import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref as sRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { databaseFirestore, storage } from '../../Firebase';

export default function AboutSchool() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);
    const [newImageURL, setNewImageURL] = useState(null);
    const [form, setForm] = useState({
        imgURL: '',
        inspiration: '',
        target: '',
        vision: '',
    });

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'about', 'details');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setData(data);
                setForm(data);
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    // Handle image upload
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const newUniqueID = uuidv4();
            const imageRef = sRef(storage, `about/${newUniqueID}`);
            setImageLoading(true);
            await uploadBytes(imageRef, file).then(() => {
                getDownloadURL(imageRef).then((url) => {
                    setNewImageURL(url);
                    setForm({
                        ...form,
                        imgURL: url,
                    });
                    setImageLoading(false);
                });
            });
        }
    };

    // Save updated data to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'about', 'details');
        await setDoc(docRef, form);
        setLoading(false);
        alert('Data updated successfully!');
    };


    if (loading) {
        return <CircularProgress className='absCenter' />;
    }
    return (
        <StyledBox>
            <Box sx={{ height: '200px', width: '100%', position: 'relative' }}>
                {imageLoading ? (
                    <CircularProgress />
                ) : (
                    <img
                        src={newImageURL || form.imgURL}
                        alt="About School"
                        style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px' }}
                        onClick={() => document.getElementById('imageUpload').click()}
                    />
                )}
                <input
                    type="file"
                    id="imageUpload"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />
            </Box>
            <TextField
                name="inspiration"
                label="Inspiration"
                multiline
                rows={4}
                fullWidth
                value={form.inspiration}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                name="target"
                label="Target"
                multiline
                rows={4}
                fullWidth
                value={form.target}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                name="vision"
                label="Vision"
                multiline
                rows={4}
                fullWidth
                value={form.vision}
                onChange={handleChange}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </StyledBox>
    );

}
