import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, Chip, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { databaseFirestore } from '../../Firebase'
import { Timestamp, addDoc, collection, doc, getDoc } from 'firebase/firestore'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function AddNotice() {
    const navigate = useNavigate()
    const [noticeObj, setNoticeObj] = useState({
        noticeTitle: '',
        noticeType: ''
    })
    const [value, setValue] = useState('');
    const [noticeTypes, setnoticeTypes] = useState([])
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchInitialData = async () => {
            if (noticeTypes.length <= 0) {
                const type = await fetchNoticeTypes();
                setnoticeTypes(type);
            }
        };
        fetchInitialData();
    }, []);



    const storeNotice = async () => {
        if (noticeObj.noticeTitle === '') {
            alert("Please enter the notice title")
            return
        }
        if (noticeObj.noticeType === '') {
            alert("Please select the notice type")
            return
        }
        if (value === "" || value === "<p><br></p>") {
            alert("Please enter content for the notice")
            return
        }
        setLoading(true)
        try {
            const myTimestamp = Timestamp.fromDate(new Date());
            await addDoc(collection(databaseFirestore, 'notices'), {
                title: noticeObj.noticeTitle,
                content: value,
                noticeType: noticeObj.noticeType,
                noticeDate: myTimestamp
            })
            setLoading(false)
            navigate("/notices")
        } catch (error) {
            alert("Not found")
            setLoading(false)
        }
    };

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Publish new notice</Typography>
                        </Stack>
                        {/* <Button variant='contained' color='primary' onClick={() => { navigate("/addnotice") }} disableElevation>Publish New notice</Button> */}
                    </Stack>
                    <br />
                    <Typography variant='subtitle1' >Enter all notice details </Typography>

                    <Stack spacing={1}>
                        <Stack direction={'row'} gap={0.5} flexWrap={'wrap'} pt={2} pb={2}>
                            {
                                noticeTypes?.types?.map((type, index) => {
                                    return <Chip label={type} key={index} onClick={() => { setNoticeObj({ ...noticeObj, noticeType: type }) }} color={noticeObj.noticeType === type ? "primary" : "secondary"} />
                                })
                            }
                        </Stack>

                        <TextField label="Title of the Notice" value={noticeObj.noticeTitle} onChange={(e) => { setNoticeObj({ ...noticeObj, noticeTitle: e.target.value }) }} />
                        <ReactQuill theme="snow" value={value} onChange={setValue} placeholder={"Type your notice here..."} />
                        <Button onClick={storeNotice} disabled={Loading} variant='contained'>Publish Notice</Button>
                    </Stack>
                </Box>
            </div>
        </div>
    )
}


async function fetchNoticeTypes() {
    const docRef = doc(databaseFirestore, "about", "noticeTypes");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data()?.types);
        return docSnap.data()
    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        return
    }
};