import React, { useEffect, useState } from 'react'
import { PageWrapper } from '../UniversalFunctions'
import { useNavigate } from 'react-router'
import { doc, getDoc } from 'firebase/firestore'
import { databaseFirestore } from '../../Firebase'
import { Backdrop, Box, Button, Chip, Divider, Grid, Modal, Paper, Stack, Typography } from '@mui/material'
import { Edit, RemoveRedEye } from '@mui/icons-material'
import { RenderDisabledFields, types } from '../Forms/CreateForm'

export default function Response({ userData }) {
  const FormIdsArray = userData ? [...userData.submittedForms].reverse() : []
  const navigate = useNavigate()
  const numberOfResultsinEachTime = 10
  const [Forms, setForms] = useState([])
  const [Loading, setLoading] = useState(false)

  console.log(Forms);

  // function for fetching the forms
  async function FetchForms(arrIds) {
    const formPromises = arrIds.map(async (id) => {
      setLoading(true)
      const docRef = doc(databaseFirestore, `forms/${id}/responses`, userData.userID)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return {
          data: docSnap.data(),
          id: docSnap.id
        }
      } else { return null }
    })
    const formData = await Promise.all(formPromises)

    setForms((prev) => {
      let filteredFormdata = formData.filter(form => form !== null)
      let prevForms = [...prev]
      let newArr = prevForms.concat(filteredFormdata)
      return newArr
    })
    setLoading(false)
  }


  // function for fetching the next forms on clicking on fetch more button 
  useEffect(() => {
    if (Forms.length > 0) { return }
    if (FormIdsArray.length < numberOfResultsinEachTime) { FetchForms(FormIdsArray) }
    else { FetchForms(FormIdsArray.splice(0, numberOfResultsinEachTime)) }
  }, [])

  // function for fetching more forms  | Pagination 
  function FetchMore() {
    if ((Forms.length + numberOfResultsinEachTime) > FormIdsArray.length) {
      FetchForms(FormIdsArray.splice(Forms.length))
    }
    else { FetchForms(FormIdsArray.splice(Forms.length, numberOfResultsinEachTime)) }
  }


  return <PageWrapper title={"Your Form Responses"} backToHome={true}>
    {
      Forms !== undefined && Forms !== null ?
        Forms.map((form, index) => {
          return <FormTile key={index} srNo={index + 1} id={form.id} data={form?.data} />
        })
        : ""
    }
    {Forms.length !== FormIdsArray.length && Forms.length > 0 ? <Button variant='outlined' fullWidth color='primary' onClick={FetchMore} disableElevation>Load more</Button> : ''}

  </PageWrapper>

}


function FormTile(props) {
  const navigate = useNavigate()
  const [FormBackDrop, setFormBackDrop] = useState({
    isOpen: false,
    data: []
  })
  return <Box p={2}>
    <Stack direction={'row'} justifyContent='space-between' >
      <Box width={'60%'}>
        <Box>
          <Typography variant='body2'>{props.data.formTitle}</Typography>
          <Typography variant='caption' color="grey">Submitted on : {props.data.submittedAt?.toDate()?.toString()?.slice(0, 21)}</Typography>
        </Box>
      </Box>
      <Button
        variant='contained'
        color='primary'
        startIcon={<RemoveRedEye fontSize='small' />}
        disableElevation
        onClick={() => {
          setFormBackDrop({
            isOpen: true,
            data: props.data
          })
        }}
      > View Response</Button>
    </Stack>
    <br />
    <FormResponseBackdrop open={FormBackDrop.isOpen} close={() => { setFormBackDrop({ isOpen: false, data: [] }) }} data={FormBackDrop.data} />
    <Divider />
  </Box>
}



export function FormResponseBackdrop({ open, close, data }) {
  return <Modal
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    onClose={close}
  >
    <Box
      width={{ md: "66%", sm: "80%", xs: "90%" }}
      height="fit-content" maxHeight="80%"
      sx={{ overflowY: "scroll", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      bgcolor="white" p={3}
      borderRadius={3}
    >
      <Typography variant='h6' color="black" mb={1}>Response for the Form</Typography>

      {/* rendering the disabled fields with the filled value from the user */}
      {
        data ?
          data?.responses?.map((field, index) => (
            <Grid item xs={12} key={field.fieldID}>
              <Paper elevation={1} style={{ padding: '16px', position: 'relative', borderRadius: 2 }}>
                <Typography variant="body1" width='96%'>
                  <Chip label={index + 1} variant='filled' size='small' /> &nbsp;{field.fieldTitle}
                </Typography>

                <Typography variant="subtitle2" color='grey' mt={1}>
                  Field Type: {field.fieldType}
                </Typography>

                {field.fieldType === types.SELECTION ? <Typography variant="subtitle2" color='grey'>
                  Options : {field.options ? field.options.map((option, index) => <span key={index}>{option}{index === field.options.length - 1 ? "" : ", "}</span>) : ""}
                </Typography> : ''}

                <br />

                <RenderDisabledFields fieldTitle={field?.fieldTitle} fieldType={field?.fieldType} value={field?.value} options={field?.options} />

              </Paper>
              <br />
            </Grid>
          ))
          : null
      }
    </Box>
  </Modal>
}