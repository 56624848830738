import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import { collection, deleteDoc, doc, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { databaseFirestore } from '../../Firebase';
import { useNavigate } from 'react-router';
import { BackdropLoader } from '../UniversalFunctions';
import { ArrowBack, ArrowForward, Delete } from '@mui/icons-material';

export default function Forms() {
    const navigate = useNavigate()
    const [Data, setData] = useState(null)
    const [lastDoc, setLastDoc] = useState(null);
    const [isListOver, setisListOver] = useState(false)
    const [Loading, setLoading] = useState(false)

    // console.log(Data);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true)
            const initialData = await fetchForms();
            setData(initialData);
            setLoading(false)
        };
        if (!Data) { fetchInitialData(); }
    }, []);

    const fetchForms = async () => {
        try {
            const dataref = collection(databaseFirestore, 'forms');
            // query(dataref, orderBy("createdAt", "desc"))
            const queryRef = lastDoc !== null ? query(dataref, orderBy("createdAt", "desc"), startAfter(lastDoc), limit(20)) : query(dataref, orderBy("createdAt", "desc"), limit(20))
            const querySnapshot = await getDocs(queryRef);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                fetchedData.push({
                    data: data,
                    id: doc.id
                });
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            if (lastVisible === undefined) { setisListOver(true) }
            setLastDoc(lastVisible);
            return fetchedData;
        } catch (error) {
            console.error('Error fetching image URLs from Firestore:', error);
            return [];
        }
    };

    const loadMoreData = async () => {
        try {
            const newData = await fetchForms();
            setData((prevData) => [...prevData, ...newData]);
        } catch (error) {
            console.error('Error loading more data:', error);
        }
    };

    return <div className="appContainer">
        {Loading ? <BackdropLoader /> : null}
        <div className="appWrapper">
            <Box p={2}>

                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => { navigate("/") }}>
                            <ArrowBack color='primary' fontSize='small' />
                        </IconButton>
                        <Typography variant='h6' color="primary" textTransform="uppercase">Forms</Typography>
                    </Stack>
                    <Button variant='contained' color='primary' onClick={() => { navigate("/createform") }} disableElevation>Create New Form</Button>
                </Stack>

                <br />
                <Stack>
                    {
                        Data !== undefined && Data !== null ?
                            Data.map((form, index) => {
                                return <FormTile
                                    key={index}
                                    srNo={index + 1}
                                    id={form.id}
                                    data={form?.data}
                                />
                            })
                            : ""
                    }
                </Stack>
                {!isListOver ? <Button variant='outlined' fullWidth color='primary' onClick={loadMoreData} disableElevation>load more</Button> : ''}
            </Box>
        </div>
    </div>
}


function FormTile(props) {
    const navigate = useNavigate()
    const deleteForm = async () => {
        const confirmed = window.confirm('Deleting any form will Restrict anyone to fill it. Are you sure you want to delete this Form?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            await deleteDoc(doc(databaseFirestore, 'forms', props.id)).then((res) => {
                navigate(0)
            })
        }
        catch (error) {
            console.error('Error deleting from Firestore:', error);
        }
    }
    return <Box
        p={2}
        sx={{ cursor: 'pointer' }}
    >
        <Stack direction={'row'} >
            <Box width={'40px'}>
                <Typography variant='body2'>{props.srNo}</Typography>
            </Box>
            <Box width={'100%'}>
                <Typography variant='body2'>{props?.data?.formTitle}</Typography>
                <Typography variant='caption' color="grey">Published on : {props.data?.createdAt?.toDate()?.toString()?.slice(0, 21)}</Typography><br />

                <Typography
                    variant='caption'
                    display="flex"
                    color='primary'
                    alignItems="center"
                    onClick={() => {
                        navigate(`/formresponses?formid=${props.id}`)
                    }}
                >View responses &nbsp;<ArrowForward sx={{ width: '16px' }} /></Typography>
            </Box>
            <Box>
                <IconButton
                    onClick={deleteForm}
                    color='error'>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Stack>
        <br />
        <Divider />
    </Box>
}