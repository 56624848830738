// BulkUploadSchools.js
import React, { useState } from 'react';
import Papa from 'papaparse';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { databaseFirestore } from '../../Firebase';
import { Box, Button, Typography } from '@mui/material';
import { BackdropLoader } from '../UniversalFunctions';

const AddBulkSchools = () => {
    const [csvFile, setCsvFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');
    const schoolCities = ["Pali", "Bali", "Sirohi", "Jalore", "Balotra ", "Barmer", "Jaisalmer", "Jodhpur Jila", "Jodhpur Mahanagar", "Nagaur", "Bikaner", "Sri Ganganagar", "Hanumangarh"]

    const handleFileUpload = (event) => {
        setCsvFile(event.target.files[0]);
    };

    const handleCitySelection = (event) => {
        setSelectedCity(event.target.value);
    };


    const handleParse = () => {
        if (!csvFile) {
            alert("Please upload a CSV file.");
            return;
        }

        if (selectedCity === "") {
            alert("Please select a city.");
            return;
        }

        setLoading(true);

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            transformHeader: header => header.trim(), // Remove spaces from headers
            complete: async (results) => {
                const schoolsCollection = collection(databaseFirestore, "schools");
                const schools = results.data;

                const promises = schools.map((school) => {
                    const name = school['विद्यामंदिर का नाम'];
                    const level = school['विद्यालय का स्तर'];
                    const address = school['पता'];
                    const principleName = school['प्रधानाचार्य का नाम'];
                    const phones = school['दूरभाष'];

                    if (name && level && address && principleName) {
                        return addDoc(schoolsCollection, {
                            name: name,
                            level: level,
                            address: address,
                            principleName: principleName,
                            phones: phones,
                            imgURL: "",
                            addedOn: serverTimestamp(),
                            city: selectedCity
                        });
                    }
                    return Promise.resolve(); // Return a resolved promise for invalid entries to keep the array length consistent
                });

                try {
                    await Promise.all(promises);
                    alert("Schools have been successfully added to Firestore.");
                } catch (error) {
                    console.error("Error adding documents: ", error);
                    alert("There was an error adding some schools to Firestore. Check the console for details.");
                } finally {
                    setLoading(false);
                    setCsvFile(null);
                }
            },
            error: (error) => {
                console.error("Error parsing CSV file: ", error);
                alert("Error parsing CSV file. Check the console for details.");
                setLoading(false);
            }
        });
    };

    return (
        <div className="appContainer">
            {loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Typography variant='h4'>Bulk Upload Schools</Typography>
                <br />
                <input type="file" accept=".csv" onChange={handleFileUpload} /><br /><br />

                <select value={selectedCity} onChange={handleCitySelection}>
                    <option value="">Select a city</option>
                    {schoolCities.map((city) => (
                        <option key={city} value={city}>{city}</option>
                    ))}
                </select>
                <br />
                <br />

                {!loading ? <Button variant='contained' onClick={handleParse}>Upload</Button> : "Adding...Please Wait..."}
            </div>
        </div>
    );
};

export default AddBulkSchools;