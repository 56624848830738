import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, CircularProgress, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { databaseFirestore } from '../../Firebase';

const SocialMedia = () => {
    const [links, setLinks] = useState({});
    const [loading, setLoading] = useState(true);
    const [newLinkName, setNewLinkName] = useState('');
    const [newLinkUrl, setNewLinkUrl] = useState('');

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'about', 'socialLinks');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setLinks(docSnap.data() || {});
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change for new link name
    const handleNewLinkNameChange = (e) => {
        setNewLinkName(e.target.value);
    };

    // Handle input change for new link URL
    const handleNewLinkUrlChange = (e) => {
        setNewLinkUrl(e.target.value);
    };

    // Handle input change for existing links
    const handleLinkChange = (e, key) => {
        setLinks({ ...links, [key]: e.target.value });
    };

    // Add new link
    const handleAddLink = () => {
        if (newLinkName.trim() === '' || newLinkUrl.trim() === '') {
            alert('Please enter both the name and URL for the new link');
            return;
        }
        setLinks({ ...links, [newLinkName]: newLinkUrl });
        setNewLinkName('');
        setNewLinkUrl('');
    };

    // Delete link
    const handleDeleteLink = (key) => {
        const updatedLinks = { ...links };
        delete updatedLinks[key];
        setLinks(updatedLinks);
    };

    // Save updated links to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'about', 'socialLinks');
        await setDoc(docRef, links);
        setLoading(false);
        alert('Social media links updated successfully!');
    };

    if (loading) {
        return <CircularProgress className='absCenter'/>;
    }

    return (
        <Box>
            {Object.keys(links).map((key) => (
                <Box key={key} display="flex" alignItems="center" marginBottom="8px">
                    <TextField
                        label={key}
                        fullWidth
                        value={links[key]}
                        onChange={(e) => handleLinkChange(e, key)}
                    />
                </Box>
            ))}
         
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
};

export default SocialMedia;
