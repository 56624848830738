import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    TextField,
    Snackbar
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, deleteDoc, getDocs, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { PageWrapper } from '../UniversalFunctions';
import { Add, RemoveRedEye } from '@mui/icons-material';

const Material = () => {
    const [materials, setMaterials] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [fileTitle, setFileTitle] = useState('');
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [classId, setClassId] = useState(null);
    const allowedFileTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];

    useEffect(() => {
        const fetchClassId = () => {
            const searchParams = new URLSearchParams(window.location.search);
            const id = searchParams.get('class');
            setClassId(id);
        };
        fetchClassId();
    }, []);

    useEffect(() => {
        const fetchMaterials = async () => {
            try {
                if (!classId) return;

                const materialsRef = collection(databaseFirestore, 'syllabus', classId, 'material');
                const querySnapshot = await getDocs(materialsRef);
                const materialsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setMaterials(materialsData);
            } catch (error) {
                console.error('Error fetching materials: ', error);
            }
        };

        fetchMaterials();
    }, [classId]);

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setFileTitle('');
        setFile(null);
    };

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };

    const handleAddFile = async () => {
        if (!file || fileTitle.trim() === '') {
            return;
        }

        if (!allowedFileTypes.includes(file.type)) {
            setErrorSnackbarOpen(true);
            return;
        }

        setUploading(true);

        try {
            // Upload file to Firebase Storage
            const storageRef = ref(storage, `syllabus/${classId}/${file.name}`);
            await uploadBytes(storageRef, file);

            // Get download URL
            const downloadURL = await getDownloadURL(storageRef);

            // Add document to Firestore
            const materialsRef = collection(databaseFirestore, 'syllabus', classId, 'material');
            const newMaterialRef = await addDoc(materialsRef, {
                fileTitle: fileTitle,
                fileUrl: downloadURL,
                createdAt: serverTimestamp()
            });

            // Refresh materials list
            const newMaterial = {
                id: newMaterialRef.id,
                fileTitle: fileTitle,
                fileUrl: downloadURL,
                createdAt: new Date()
            };
            setMaterials([...materials, newMaterial]);

            // Close dialog
            handleCloseAddDialog();
        } catch (error) {
            console.error('Error uploading file: ', error);
        } finally {
            setUploading(false);
        }
    };

    const handleDeleteMaterial = async (materialId) => {
        try {
            const materialRef = doc(databaseFirestore, 'syllabus', classId, 'material', materialId);
            await deleteDoc(materialRef);
            const updatedMaterials = materials.filter(material => material.id !== materialId);
            setMaterials(updatedMaterials);
        } catch (error) {
            console.error('Error deleting material: ', error);
        }
    };

    const handleSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    return <PageWrapper title={"Materials"}>
        <Box mt={2}>
            <Button
                variant='contained'
                color='primary'
                onClick={handleOpenAddDialog}
                disableElevation
                startIcon={<Add/>}
            >
                Add New Doc
            </Button>
        </Box>

        <Box mt={2}>
            <List>
                {materials.map((material, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={material.fileTitle} />
                        <ListItemSecondaryAction>
                            <IconButton component='a' href={material.fileUrl} target='_blank' download>
                                <RemoveRedEye />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteMaterial(material.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Box>

        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
            <DialogTitle>Add New Doc</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide a title and upload a file.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="File Title"
                    fullWidth
                    value={fileTitle}
                    disabled={uploading}
                    onChange={(e) => setFileTitle(e.target.value)}
                />
                <input
                    type="file"
                    disabled={uploading}
                    onChange={handleFileChange}
                    style={{ marginTop: '20px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddDialog} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleAddFile}
                    color="primary"
                    variant='contained'
                    disableElevation
                    disabled={uploading}
                >
                    {uploading ? 'Uploading...' : 'Add File'}
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={errorSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message="File type not allowed."
        />
    </PageWrapper>

};

export default Material;
