import { ArrowBack } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";

export function BackdropLoader() {
    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={() => { }}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
}


export function PageWrapper({ children, backToHome, title, buttonTitle, buttonAction, secondaryTitle, backdropLoading }) {
    const navigate = useNavigate()
    return <div className="appContainer">
        {backdropLoading ? <BackdropLoader /> : ""}
        <div className="appWrapper">
            <Box p={2}>

                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => { backToHome ? navigate('/') : navigate(-1) }}>
                            <ArrowBack color='primary' fontSize='small' />
                        </IconButton>
                        <Stack direction='column'>
                            <Typography variant='h6' color="primary" textTransform="uppercase">{title}</Typography>
                            <Typography variant='body2' color="gray">{secondaryTitle} </Typography>
                        </Stack>

                    </Stack>
                    {buttonTitle ? <Button variant='contained' color='primary' onClick={buttonAction} disableElevation>{buttonTitle}</Button> : null}
                </Stack>
                {children}
                <br />
            </Box>
        </div>
    </div>
}