import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { databaseFirestore } from '../../Firebase';

const Stats = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({
        schools: '',
        students: '',
        teachers: '',
    });

    // Fetch initial data from Firestore
    useEffect(() => {
        const fetchInitialData = async () => {
            const docRef = doc(databaseFirestore, 'about', 'stats');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setData(data);
                setForm(data);
            } else {
                alert("Not found")
            }
            setLoading(false);
        };

        fetchInitialData();
    }, []);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: +value,
        });
    };

    // Save updated data to Firestore
    const handleSave = async () => {
        setLoading(true);
        const docRef = doc(databaseFirestore, 'about', 'stats');
        await setDoc(docRef, form);
        setLoading(false);
        alert('Data updated successfully!');
    };

    if (loading) {
        return <CircularProgress className='absCenter'/>;
    }

    return (
        <Box>
            <TextField
                name="schools"
                label="Schools"
                type="number"
                fullWidth
                value={form.schools}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                name="students"
                label="Students"
                type="number"
                fullWidth
                value={form.students}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                name="teachers"
                label="Teachers"
                type="number"
                fullWidth
                value={form.teachers}
                onChange={handleChange}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
};

export default Stats;
