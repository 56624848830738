import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Paper, CircularProgress, Stack, Chip } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, serverTimestamp, Timestamp, arrayRemove, arrayUnion, updateDoc, setDoc } from 'firebase/firestore';
import { AttachFile, Send } from '@mui/icons-material';
import { databaseFirestore, storage } from '../../Firebase';
import { BackdropLoader } from '../UniversalFunctions';
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { FormValidation } from './FormDatabaseFunctions';

const FillForm = ({ userData }) => {

    const [form, setForm] = useState(null);
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const formId = searchParams.get("formid")


    // checking the form is filled by the current user or not
    const isFormFilled = !userData.anyNewForm.includes(formId)

    console.log(form);

    // fetching the form details
    useEffect(() => {
        const fetchForm = async () => {
            try {
                setLoading(true)
                const formRef = doc(databaseFirestore, 'forms', formId);
                const formSnap = await getDoc(formRef);

                if (formSnap.exists()) {
                    const formData = formSnap.data();
                    setForm(formData);
                    setResponses(
                        formData.fields.map(field => ({
                            fieldID: field.fieldID,
                            fieldType: field.fieldType,
                            fieldTitle: field.fieldTitle,
                            options: field.options || [],
                            value: '',
                        }))
                    );
                } else {
                    console.log('No such document!');
                    alert("Form not found. The link may be expired or broken")
                }
            } catch (error) {
                console.error('Error fetching form:', error);
            } finally {
                setLoading(false);
            }
        };

        if (formId && form === null) { fetchForm() }
        else {
            alert("Form ID not found")
            return
        }
    }, [formId]);

    const handleChange = (index, value) => {
        const updatedResponses = [...responses];
        updatedResponses[index].value = value;
        setResponses(updatedResponses);
    };



    const handleSubmit = async () => {
        if (!FormValidation(responses)) { return }
        setSubmitting(true);
        try {
            const updatedResponses = await Promise.all(responses.map(async (response) => {
                if (response.fieldType === 'file' && response.value instanceof File) {
                    const fileRef = sRef(storage, `forms/${formId}/${response.value.name}`);
                    await uploadBytes(fileRef, response.value);
                    const fileUrl = await getDownloadURL(fileRef);
                    return {
                        ...response,
                        value: fileUrl,
                    };
                } else {
                    return response;
                }
            }));
            const myTimestamp = Timestamp.fromDate(new Date());
            const collectionRef = collection(databaseFirestore, `forms/${formId}/responses`)
            const docRef = doc(collectionRef, userData.userID)
            await setDoc(docRef, {
                formTitle: form.formTitle,
                responses: updatedResponses,
                submittedAt: myTimestamp,
                submittedByuserID: userData.userID, // Replace with actual user ID
                submittedByuserName: userData.userName, // Replace with actual user ID
            })
            // await addDoc(collection(databaseFirestore, 'forms', formId, 'responses'), {
            //     responses: updatedResponses,
            //     submittedAt: myTimestamp,
            //     submittedByuserID: userData.userID, // Replace with actual user ID
            //     submittedByuserName: userData.userName, // Replace with actual user ID
            // });
            MarkingFormAsSubmittedByThisUser(formId, userData.userID)
            // Redirect or perform any additional actions after submission
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        setSubmitting(false);
    };




    // function for marking the form as submitted by this user which is currently logged in. This function basically removes the form id from the anyNewFrom field in the user object and add this id into subittedForms field. that makes this form as submitted
    async function MarkingFormAsSubmittedByThisUser(formId, userId) {
        const docRef = doc(databaseFirestore, 'users', userId)
        try {
            await updateDoc(docRef, { anyNewForm: arrayRemove(formId) })
            await updateDoc(docRef, { submittedForms: arrayUnion(formId) })
            alert('Form submitted successfully');
            navigate('/schoolforms')
            navigate(0)
        } catch (error) {
            console.log(error);
        }
    }

    // function for render ing the fields accorfin to their type
    function renderFields(field, index) {
        if (field.fieldType === 'text') {
            return <TextField
                fullWidth
                label="Type here..."
                margin="normal"
                value={responses[index]?.value || ''}
                onChange={(e) => handleChange(index, e.target.value)}
            />
        }

        if (field.fieldType === 'content') {
            return <TextField
                fullWidth
                label="Type here..."
                multiline
                rows={4}
                margin="normal"
                value={responses[index]?.value || ''}
                onChange={(e) => handleChange(index, e.target.value)}
            />
        }

        if (field.fieldType === 'selection') {
            return <FormControl fullWidth margin="normal">
                <InputLabel>{field.fieldTitle}</InputLabel>
                <Select
                    value={responses[index]?.value || ''}
                    onChange={(e) => handleChange(index, e.target.value)}
                >
                    {field.options.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        }

        if (field.fieldType === 'file') {
            return <Box>
                <Typography variant='body2' mt={2}>Attached File : {responses[index].value !== "" ? responses[index].value?.name : "None"}</Typography>
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<AttachFile />}
                    component="label"
                    sx={{ mt: 2 }}
                >
                    Attach File
                    <input
                        type="file"
                        hidden
                        onChange={(e) => handleChange(index, e.target.files[0])}
                    />
                </Button>
            </Box>
        }


    }

    // returning the loading if page is loading
    if (loading) { return <BackdropLoader /> }

    if (isFormFilled) {
        alert('This form is already been submitted by you')
        navigate('/schoolforms')
        return
    }

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={3}>
                    <Typography variant="h5" gutterBottom> {form?.formTitle || 'Form'}  </Typography>
                    <Typography variant="body2" gutterBottom color='grey'> Fill all the fields below and click on submit to submit the form </Typography>
                    <Typography variant="body2" gutterBottom color='grey'> Form Published on : {form?.createdAt?.toDate()?.toString()?.slice(0, 21)} </Typography>
                    <Typography variant="body2" gutterBottom color='grey'> Form Published by : Vidyabharti Jodhpur Prant </Typography>
                    <br />

                    {/* rendering the fields of the form  */}
                    <Stack direction='column' spacing={2}>
                        {
                            !loading ? form?.fields.map((field, index) => (
                                <Paper elevation={1} style={{ padding: '16px', borderRadius: 2 }} key={index}>
                                    <Typography variant="body1" display='flex' alignItems='center'>  {field.fieldTitle} </Typography>
                                    {renderFields(field, index)}
                                </Paper>
                            )) : ''}
                    </Stack>

                    {
                        responses.length > 0 ? <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Send />}
                            onClick={handleSubmit}
                            disabled={submitting}
                            style={{ marginTop: '16px' }}
                        >
                            {submitting ? <CircularProgress size={24} /> : 'Submit Form'}
                        </Button> : ''
                    }
                </Box>
            </div>
        </div>
    );
};

export default FillForm;