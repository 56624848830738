import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ShowMaterialIcon from '@mui/icons-material/MenuBook';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBack from '@mui/icons-material/ArrowBack';
// import { useHistory } from 'react-router-dom';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { databaseFirestore, storage } from '../../Firebase';
// import { storage } from '../../firebase';
import { useNavigate } from 'react-router';
import { deleteObject } from 'firebase/storage';

const Syllabus = () => {
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const navigate = useNavigate();

    console.log(classes);

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const querySnapshot = await getDocs(collection(databaseFirestore, 'syllabus'));
                const classesData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setClasses(classesData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        fetchClasses();
    }, []);

    const handleShowMaterials = (classId) => {
        // Navigate to the page that shows materials for this class
        // history.push(`/class/${classId}`);
        navigate(`/classmaterial?class=${classId}`)
    };

    const handleDeleteClass = async () => {
        if (selectedClass) {
            const classId = selectedClass.id;
            const classRef = doc(databaseFirestore, 'syllabus', classId);

            try {
                // Delete class document from Firestore
                await deleteDoc(classRef);

                // Delete class folder and all materials from Firebase Storage
                const classFolderRef = storage.ref(`syllabus/${classId}`);
                await deleteObject(classFolderRef);

                // Update state to remove the deleted class
                const updatedClasses = classes.filter(cls => cls.id !== classId);
                setClasses(updatedClasses);

                // Close delete dialog and reset selected class
                setSelectedClass(null);
                setOpenDeleteDialog(false);
                navigate(0)
            } catch (error) {
                console.error('Error deleting class:', error);
                setOpenDeleteDialog(false);
                navigate(0)
            }
        }
    };

    const columns = [
        { field: 'className', headerName: 'Class Name', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleShowMaterials(params.row.id)}>
                        <ShowMaterialIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                        setSelectedClass(params.row);
                        setOpenDeleteDialog(true);
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        }
    ];

    if (loading) {
        return <CircularProgress className='absCenter' />;
    }

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                onClick={() => navigate('/')}
                            >
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Syllabus</Typography>
                        </Stack>
                        <Button variant='contained' color='primary'
                            onClick={() => navigate('/addclass')}
                            disableElevation>Add Class</Button>
                    </Stack>

                    <Box mt={2}>
                        <DataGrid
                            rows={classes}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            disableSelectionOnClick
                            sx={{ height: "75vh" }}
                        />
                    </Box>

                    <Dialog
                        open={openDeleteDialog}
                        onClose={() => setOpenDeleteDialog(false)}
                    >
                        <DialogTitle>Delete Class</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Deleting this class will delete all its materials. Are you sure you want to delete this class?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteClass} color="primary" variant='contained' disableElevation>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div>
        </div>
    );
};

export default Syllabus;
