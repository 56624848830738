import { onAuthStateChanged } from 'firebase/auth';
import React, { cloneElement, useEffect, useState } from 'react'
import { AuthVariable, databaseFirestore } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import { BackdropLoader } from './UniversalFunctions';
import { doc, getDoc } from 'firebase/firestore';
import { Permissions } from '../Permissions';
import NotAllowed from './NotAllowed';

// checking authentication  
export default function ProtectedLogin({ children, child }) {
    // 0 for user details is not fetched yet
    // 1 for user is not logged in redirect him to login page
    // 2 for user is logged in redirect him to target children page after checking the permission of that page
    const [isAuthenticated, setisAuthenticated] = useState(0)
    const [AccessLevel, setAccessLevel] = useState(null) // for setting the level of the user. from this we are getting the permission of the user
    const userPermission = Permissions(AccessLevel) // we have defined all the permissions in the permisson.js page. from there we are getting permissions
    const [userData, setUserData] = useState({
        userName: '',
        userEmail: '',
        accessLevel: '',
        userID: ''
    })

    // checking if any user is logged in or not. If yes then checking the accessLevel of that user and returning the child accodrdingly
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(AuthVariable, (currentuser) => {
            if (currentuser !== null && currentuser !== undefined) {
                const docRef = doc(databaseFirestore, 'users', currentuser.uid)
                getDoc(docRef).then((res) => {
                    setisAuthenticated(2)
                    setUserData({
                        userName: res.data().userName,
                        userEmail: res.data().email,
                        accessLevel: res.data().accessLevel,
                        userID: res.id,
                        anyNewForm: res.data().anyNewForm ? res.data().anyNewForm : [],
                        submittedForms: res.data().submittedForms ? res.data().submittedForms : []
                    })
                    setAccessLevel(res.data()?.accessLevel)
                }).catch((error) => {
                    console.log(error.message);
                    setisAuthenticated(1)
                })
            }
            else { setisAuthenticated(1) }
        });
        return unsubscribe;
    }, []);

    // going to login if currentuser is null or undefined || there is some error in getting the data of the user
    if (isAuthenticated === 1) { return <Login /> }

    // checking that the user is logged in or not. 
    else if (isAuthenticated === 2) {

        // if user is logged in the checking that if he has permission for viewing that page or not? 
        if (userPermission[child]) {
            const childrenWithProps = cloneElement(children, { accessLevel: AccessLevel, userData: userData })
            return childrenWithProps
        }
        else { return <NotAllowed /> }
    }

    // return loader in other cases
    else return <BackdropLoader />
}
