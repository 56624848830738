import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, Chip, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../../Styles/AddBlog.css"
import { ref as sRef } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { databaseFirestore, storage } from '../../Firebase';
import { PageWrapper } from '../UniversalFunctions';

export default function AddBlog() {
    const titleRef = useRef(null)
    const navigate = useNavigate()
    const [URL, setURL] = useState('')
    const [value, setValue] = useState('');
    const [Loading, setLoading] = useState(false)


    const ImagesUploadLimit = 3
    var imagesUpload = []; // for carring the images to be uploaded.
    var ForImagePreview = []; //For carring the pre urls of the images to be uploaded.
    const imageUrls = []; // For carring the urls of the images after upload
    const [pI, setPI] = useState([]) //Just for holding values, All the links for showing the image into the container before uploading them to firebase. 
    const [uIm, setUIm] = useState([]) // just for holding values, ALl the images to be upload will store in this state. 



    // function fot getting the blogs categories
    const [categories, setCategories] = useState([])
    const [CategorySelected, setCategorySelected] = useState('')

    useEffect(() => {
        const fetchInitialData = async () => {
            if (categories.length <= 0) {
                const cat = await FetchCategories();
                setCategories(cat);
            }
        };
        fetchInitialData();
    }, []);

    const FetchCategories = async () => {
        const docRef = doc(databaseFirestore, "BlogCategories", "categories");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data()?.categories);
            return docSnap.data()
        } else {
            // docSnap.data() will be undefined in this case
            alert("Not found")
            return
        }
    };




    const handleSubmit = (e) => {
        // Only allowed file
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];

        if (e.target.files.length <= ImagesUploadLimit) {
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = e.target.files[i];

                if (ALLOWED_TYPES.includes(newImage.type) && e.target.files[i].size < 8388608) {
                    imagesUpload.push(newImage);
                    // generating fake/preUrls for images preview on dom before upload
                    ForImagePreview.push({ preURL: global.URL.createObjectURL(newImage) });
                }

                else {
                    alert(`File ${newImage.name} is not supported or bigger than 8 MB`);
                    return
                }
            }
            setPI(ForImagePreview)
            setUIm(imagesUpload)
        }
        else {
            alert(`Sorry :-( You can select maximum ${ImagesUploadLimit} images.`)
        };
    };


    const uploadImage = async () => {
        if (uIm.length === 0) {
            alert("Please select an image")
            return
        };
        setLoading(true)
        for (let i = 0; i < uIm.length; i++) {
            const newUniqueID = uuidv4()
            const imageRef = sRef(storage, `blogs/${newUniqueID}`);
            await uploadBytes(imageRef, uIm[i]).then(() => {
                // console.log(`Image ${i} uploaded successfully`);
                getDownloadURL(imageRef).then((url) => {
                    // console.log("Here is the link", url);
                    imageUrls.push({
                        url: url,
                    });
                    // console.log("Img urls length : " + imageUrls.length + ' number of imgs. : ' + uIm.length);
                    if (imageUrls.length === uIm.length) {
                        // console.log("Now i can upload object url");
                        // UploadURL(imageUrls)
                        // ValidateFeilds()
                        const content = LinksManagement(value)
                        publishBlog(content)
                    }
                });
            });
        }

    }


    const ValidateFeilds = () => {
        if (titleRef.current.value === "") {
            alert("Please enter a Blog title")
            return
        }
        if (value === "" || value === "<p><br></p>") {
            alert("Please enter content for the blog")
            return
        }
        if (CategorySelected === "") {
            alert("Please enter a Category")
            return
        }
        uploadImage()
    }


    // checking if a blog content contains any links or not?
    const LinksManagement = (flag) => {
        return flag;
    }

    const publishBlog = async (con) => {
        setLoading(true)
        const myTimestamp = Timestamp.fromDate(new Date());
        const NewId = uuidv4()
        await setDoc(doc(databaseFirestore, "blogs", NewId), {
            title: titleRef.current.value,
            content: con,
            imgURL: imageUrls,
            createdAt: myTimestamp,
            views: 0,
            category: CategorySelected
            // viewCount: 0
        }).then(() => {
            // console.log("Blog uploaded successfully, Creating Views Feild......");
            CreateViewCollection(NewId)
            setLoading(false);
            // navigate("/");
            navigate(-1);
        })
    }

    // creating different feild for storing views of the blog
    const CreateViewCollection = async (Blogid) => {
        await setDoc(doc(databaseFirestore, "BlogViews", Blogid), {
            viewCount: 0
        })
    }

    return <PageWrapper title={'Add New Blog'}>
            <br />
            <div className='AddBlog_parentContainer'>
                <div className="AddBlog_Wrapper">
                    <div className='PostBlogButtonContainer'>
                        <Typography variant='body2'>Write blog here...</Typography>
                        <Button
                            variant='contained'
                            disableElevation
                            onClick={ValidateFeilds} >
                            Publish Blog
                        </Button>

                    </div>
                    <input className='Title_input' type="text" ref={titleRef} placeholder='Title of the blog' />
                    <Stack direction={'row'} gap={0.5} flexWrap={'wrap'} pt={2} pb={2}>
                        {
                            categories?.categories?.map((cat, index) => {
                                return <Chip label={cat} key={index} onClick={() => { setCategorySelected(cat) }} color={CategorySelected === cat ? "primary" : "secondary"} />
                            })
                        }
                    </Stack>
                    <label htmlFor="fileUpload" className="image-upload">
                        {
                            pI.length === 0 ? <div className="beforeUpload">
                                {/* <img src={upload} alt="icon" /> */}
                                <Typography variant='body2'> Add Image</Typography>
                            </div> : <div id="imgPreview">
                                {pI.map((key) => (<img src={key.preURL} />))}
                            </div>
                        }
                        <div id="Loader" style={{ display: "none" }}>
                            <ImageLoading />
                        </div>
                    </label>
                    <input type="file" id="fileUpload" multiple className="forHide" onChange={handleSubmit} />
                    <ReactQuill theme="snow" value={value} onChange={setValue} placeholder={"Type your blog content here..."} />
                </div>
            </div>
            {Loading ? <UniversalLoader /> : ""}

        </PageWrapper>
}

function ImageLoading() {
    return <div className="ImageLoading"></div>
}

export function UniversalLoader() {
    return <div className="universal_Loader">
        <CircularProgress />
    </div>
}