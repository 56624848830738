import { FieldValue, Timestamp, addDoc, arrayUnion, collection, doc, getDoc, setDoc, updateDoc,getDocs, query, where, orderBy } from "firebase/firestore";
import { AuthVariable, databaseFirestore } from "../../Firebase";
import * as XLSX from 'xlsx';
import { createUserWithEmailAndPassword } from "firebase/auth";




// function for creating a new form into our database
export async function AddForm(formData, setLoading, formTitle, navigate) {
    if (formTitle === '') {
        alert('Please enter a form title') //validating the form title
        return
    }
    try {
        setLoading(true)
        const myTimestamp = Timestamp.fromDate(new Date());
        const docRef = await addDoc(collection(databaseFirestore, 'forms'), {
            fields: formData,
            createdAt: myTimestamp,
            createdBy: 'Admin',
            formTitle: formTitle
        })
        SendingFormToUsers(docRef.id, navigate)
        setLoading(false)

    } catch (error) {
        alert(error?.code)
        setLoading(false)
    }
}

// storing the newly created form id to the all of the users object for showing that a new form is published
export function SendingFormToUsers(formId, navigate) {
    const usersRef = doc(databaseFirestore, 'about', 'schoolsUsers')
    getDoc(usersRef).then((res) => {
        const users = [...res.data().users]
        if (users) {
            users.forEach((user) => {
                SetNewFormToUserObject(user, formId)
            })
        }
        navigate('/forms')
    })
}


// storing the newly created form id to the all of the users object for showing that a new form is published
async function SetNewFormToUserObject(user, formId) {
    const docRef = doc(databaseFirestore, 'users', user)
    await updateDoc(docRef, {
        anyNewForm: arrayUnion(formId)
    })
}

// a basic validation for forms fields
export function FormValidation(responses) {
    for (let i = 0; i < responses.length; i++) {
        if (responses[i].value === "") {
            alert(`Please answer the question : ${responses[i].fieldTitle}`)
            return false
        }
    }
    return true
}

// function for exporting our form response data into hte excel
export function ExportToExcel(formData) {
    console.log(formData);
    if (!formData) {
        alert("Something Went Wrong...")
        return
    }
    const data = [
        [`Title : ${formData.formTitle}`], // Add the form title at the top
        [`Submitted By : ${formData.submittedByuserName}`], //Adding the name of the submitter
        [`Submitted On : ${formData.submittedAt?.toDate()?.toString()?.slice(0, 21)}`], //Adding the time and date of the submission
        [], // Empty row for spacing
        ['Question', 'Type', 'Options', 'Answer'], // Column headers
    ];

    formData.responses.forEach((field) => {
        let newRow = [
            field.fieldTitle,
            field.fieldType,
            field.options.length > 0 ? field.options.join() : '',
            field.value
        ]
        data.push(newRow)
    })

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, `Response_${formData?.submittedByuserName}.xlsx`);

}


// function for creating a new user object in our users collection 
export async function CreateUser(uid, email, userName) {
    try {
        const collectionRef = collection(databaseFirestore, `users`)
        const docRef = doc(collectionRef, uid)
        await setDoc(docRef, {
            accessLevel: '2',
            email: email,
            anyNewForm: [],
            submittedForms: [],
            userName: userName
        })
        const userNamesRef = doc(databaseFirestore, 'about', 'schoolsUsers')
        await updateDoc(userNamesRef, { users: arrayUnion(uid) })
    } catch (error) {
        console.log(error);
    }
}

export async function CreatingNewUser(email, password) {
    createUserWithEmailAndPassword(AuthVariable, email, password)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            console.log(user);

        })
        .catch((error) => {
            console.log(error.message);
        });
}



export async function exportFormResponsesToExcel(formId) {
    // Step 1: Fetch Form and Responses Data
    const formRef = doc(databaseFirestore, 'forms', formId);
    const formDoc = await getDoc(formRef);
    
    if (!formDoc.exists()) {
        alert('Form not found!');
        return;
    }
    
    const formData = formDoc.data();
    const responsesRef = collection(databaseFirestore, `forms/${formId}/responses`);
    const responsesQuery = query(responsesRef, orderBy('submittedAt', 'desc'));
    const responsesSnapshot = await getDocs(responsesQuery);
    
    const responsesData = [];
    responsesSnapshot.forEach(doc => {
        responsesData.push({
            id: doc.id,
            ...doc.data()
        });
    });
    
    // Step 2: Prepare Data for Excel Export
    const headerRow = [
        'Name of the School',
        'Response Date & Time',
        ...formData.fields.map(field => field.fieldTitle) // Add question titles
    ];
    
    const bodyRows = responsesData.map(response => {
        const responseRow = [
            response.submittedByuserName,
            response.submittedAt?.toDate().toString().slice(0, 21), // Format the response date
            ...response.responses.map(field => field.value) // Extract the answers
        ];
        return responseRow;
    });
    
    const data = [
        [`Form Title: ${formData.formTitle}`],
        [`Form Creation Date: ${formData.createdAt.toDate().toString().slice(0, 21)}`],
        [], // Empty row for spacing
        headerRow,
        ...bodyRows
    ];
    
    // Step 3: Create Excel Workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
    
    // Step 4: Export Excel File
    XLSX.writeFile(workbook, `Responses_${formData.formTitle}.xlsx`);
}
