// src/components/FormResponses.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { databaseFirestore } from '../../Firebase';
import { exportFormResponsesToExcel } from './FormDatabaseFunctions';
import { PageWrapper } from '../UniversalFunctions';

export default function FormResponses() {
    const [searchParams] = useSearchParams();
    const formId = searchParams.get("formid");
    const navigate = useNavigate();
    const [Data, setData] = useState(null);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            const initialData = await fetchFormResponses();
            setData(initialData);
            setLoading(false);
        };
        if (!Data) { fetchInitialData(); }
    }, [formId]);

    const fetchFormResponses = async () => {
        try {
            const dataref = collection(databaseFirestore, `forms/${formId}/responses`);
            const queryRef = query(dataref, orderBy("submittedAt", "desc"));
            const querySnapshot = await getDocs(queryRef);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                fetchedData.push({
                    submittedBy: data.submittedByuserName,
                    submittedAt: data.submittedAt?.toDate()?.toString()?.slice(0, 21)
                });
            });
            return fetchedData;
        } catch (error) {
            console.error('Error fetching responses:', error);
            return [];
        }
    };

    const handleExport = () => {
        exportFormResponsesToExcel(formId);
    };

    return (
        <PageWrapper title={"Responses of the Form"}>
            <Button
                variant='contained'
                color='success'
                onClick={handleExport}
                sx={{ mb: 2 }}
            >
                Export All Responses to Excel
            </Button>
            <Stack spacing={2}>
                {Data && Data.length > 0 ? (
                    Data.map((response, index) => (
                        <Box key={index} p={2} sx={{ border: '1px solid #ddd', borderRadius: '4px' }}>
                            <Typography variant='body2'><strong>Submitted By:</strong> {response.submittedBy}</Typography>
                            <Typography variant='body2'><strong>Submitted At:</strong> {response.submittedAt}</Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant='body2'>No responses available.</Typography>
                )}
            </Stack>
        </PageWrapper>
    );
}
