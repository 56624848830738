import ArrowBack from '@mui/icons-material/ArrowBack'
import { Backdrop, Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, deleteDoc, doc, getDocs, setDoc, orderBy, query, limit, startAfter } from 'firebase/firestore';
import Delete from '@mui/icons-material/Delete';
import { BackdropLoader } from '../UniversalFunctions';

export default function Notices() {
  const navigate = useNavigate()
  const [Data, setData] = useState(null)
  const [lastDoc, setLastDoc] = useState(null);
  const [isListOver, setisListOver] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [NoticeDetailsBackdrop, setNoticeDetailsBackdrop] = useState({
    state: false,
    data: ["sadc"]
  })

  // console.log(Data);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true)
      const initialData = await fetchNotices();
      setData(initialData);
      setLoading(false)
    };
    if (!Data) { fetchInitialData(); }
  }, []);

  const fetchNotices = async () => {
    try {
      const dataref = collection(databaseFirestore, 'notices');
      // query(dataref, orderBy("createdAt", "desc"))
      const queryRef = lastDoc !== null ? query(dataref, orderBy("noticeDate", "desc"), startAfter(lastDoc), limit(20)) : query(dataref, orderBy("noticeDate", "desc"))
      const querySnapshot = await getDocs(queryRef);
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        fetchedData.push({
          data: data,
          id: doc.id
        });
      });
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      if (lastVisible === undefined) { setisListOver(true) }
      setLastDoc(lastVisible);
      return fetchedData;
    } catch (error) {
      console.error('Error fetching image URLs from Firestore:', error);
      return [];
    }
  };

  const loadMoreData = async () => {
    try {
      const newData = await fetchNotices();
      setData((prevData) => [...prevData, ...newData]);
    } catch (error) {
      console.error('Error loading more data:', error);
    }
  };

  return (
    <div className="appContainer">
      {Loading ? <BackdropLoader /> : ""}
      <div className="appWrapper">
        <Box p={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={() => { navigate('/') }}>
                <ArrowBack color='primary' fontSize='small' />
              </IconButton>
              <Typography variant='h6' color="primary" textTransform="uppercase">Notices</Typography>
            </Stack>
            <Button variant='contained' color='primary' onClick={() => { navigate("/addnotice") }} disableElevation>Publish New notice</Button>
          </Stack>
          <br />
          <Typography variant='subtitle1' mb={2} >All notices </Typography>
          <Stack>
            {
              Data !== undefined && Data !== null ?
                Data.map((notice, index) => {
                  return <NoticeTile key={index} srNo={index + 1} id={notice.id} data={notice?.data} setNoticeDetailsBackdrop={setNoticeDetailsBackdrop} />
                })
                : ""
            }
          </Stack>
        </Box>
      </div>
      <NoticeBackdrop open={NoticeDetailsBackdrop.state} close={() => { setNoticeDetailsBackdrop(false) }} data={NoticeDetailsBackdrop.data} />
    </div>
  )
}


function NoticeTile(props) {
  const navigate = useNavigate()
  // function for deleting the image
  const deleteHof = async () => {
    // Show confirmation dialog
    const confirmed = window.confirm('Are you sure you want to delete this Notice?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      await deleteDoc(doc(databaseFirestore, 'notices', props.id)).then((res) => {
        navigate(0)
      })
      // console.log('deleted from Firestore successfully.');
    }
    catch (error) {
      console.error('Error deleting from Firestore:', error);
    }
  }
  return <Box
    p={2}
    onClick={() => {
      props.setNoticeDetailsBackdrop({
        state: true,
        data: props.data
      })
    }}
    sx={{ cursor: 'pointer' }}
  >
    <Stack direction={'row'} >
      <Box width={'40px'}>
        <Typography variant='body2'>{props.srNo}</Typography>
      </Box>
      <Box width={'100%'}>
        <Typography variant='body2'>{props?.data?.title}</Typography>
        <Typography variant='caption' color="grey">Published on : {props.data?.noticeDate?.toDate()?.toString()?.slice(0, 21)}</Typography>
      </Box>
      <Box>
        <IconButton onClick={deleteHof} color='error'>
          <Delete fontSize='small' />
        </IconButton>
      </Box>
    </Stack>
    <br />
    <Divider />
  </Box>
}


function NoticeBackdrop({ open, close, data }) {
  // function for creating html and setting Dangerously into Typography Element.  
  function createMarkup(content) {
    return { __html: content };
  }

  return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    onClick={close}
  >
    <Box width={{ md: "40%", sm: "80%", xs: "90%" }} height="fit-content" maxHeight="80%" sx={{ overflowY: "scroll" }} bgcolor="white" p={3} borderRadius={3}>
      <Typography variant='h5' color="black" mb={-1}>{data?.title}</Typography>
      <Typography variant='caption' color="black">{data?.noticeDate?.toDate()?.toString()?.slice(0, 21)}</Typography>
      <Divider />
      <Typography variant='body2' color="black" dangerouslySetInnerHTML={createMarkup(data?.content)}></Typography>
    </Box>
  </Backdrop>
}