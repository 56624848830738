import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { databaseFirestore, storage } from '../../Firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { Timestamp, addDoc, collection } from 'firebase/firestore'

export default function AddHOF() {
    const navigate = useNavigate()
    const [imgURL, setimgURL] = useState('') // uploaded url 
    const [loading, setLoading] = useState(false);
    const [imageToUpload, setImageToUpload] = useState(null)
    const [masterLoading, setMasterLoading] = useState(false)

    const [hofDetails, setHofDetails] = useState({
        name: '',
        post: '',
        school: '',
        description: '',
    })


    // console.log(imgURL);
    // console.log(hofDetails);

    // function for hading the image upload
    const handleChangeImage = (e) => {
        const selectedImage = e.target.files[0];

        // if no image is selected then eat 5 star do nothing 
        if (!selectedImage) return;

        // Check if the selected file type is allowed
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedTypes.includes(selectedImage.type)) {
            alert('Only PNG, JPEG, and JPG files are allowed.');
            return;
        }

        // Check if the selected file size is less than 4MB
        const maxSize = 3 * 1024 * 1024; // 4MB in bytes
        if (selectedImage.size > maxSize) {
            alert('Image size must be less than 3MB.');
            return;
        }

        uploadImage(selectedImage)

    };


    const uploadImage = async (image) => {
        // Upload the selected image to Firebase Storage
        setLoading(true);
        const imageRef = ref(storage, `HallOfFame/${image.name}`);
        await uploadBytes(imageRef, image);

        // Get the download URL of the uploaded image
        const downloadURL = await getDownloadURL(imageRef);
        setimgURL(downloadURL);
        setLoading(false);

        // Inside handleChangeImage function after obtaining imageURL
        // storeImageURLInFirestore(downloadURL);
    }


    const addHofObject = async () => {
        if (hofDetails.name === '' || hofDetails.post === "" || hofDetails.school === "" || hofDetails.description === "" || imgURL === "") {
            alert("Please enter all the details")
            return
        }
        setMasterLoading(true)

        try {
            const myTimestamp = Timestamp.fromDate(new Date());
            await addDoc(collection(databaseFirestore, 'hallOfFame'), {
                content: hofDetails.description,
                imgURL: imgURL,
                name: hofDetails.name,
                post: hofDetails.post,
                school: hofDetails.school,
                createdAt: myTimestamp
                // other feilds
            })
            // await setDoc(docRef, { imgURL: imageURL, imgAdded: myTimestamp });
            setMasterLoading(false)
            navigate("/halloffame")
        } catch (error) {
            // console.error('Error storing in Firestore:', error)
            setMasterLoading(false)
        }
    };

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Add new </Typography>
                        </Stack>
                    </Stack>
                    <br />

                    <Stack direction="column" gap={3}>
                        <div className="container" onClick={() => document.getElementById(`input1`).click()}>
                            <Box width='100%' height={150} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#D9D9D9'} borderRadius={4} overflow={'hidden'}>
                                {loading ? (
                                    <CircularProgress />
                                ) : imgURL ? (
                                    <img src={imgURL} alt={`Image`} width={'100%'} />
                                ) : (
                                    <p>Upload an image</p>
                                )}
                                <input
                                    id={`input1`}
                                    type="file"
                                    onChange={handleChangeImage}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                            <br />
                        </div>
                        <TextField label="Name" value={hofDetails.name} onChange={(e) => { setHofDetails({ ...hofDetails, name: e.target.value }) }} />
                        <TextField label="Post" value={hofDetails.post} onChange={(e) => { setHofDetails({ ...hofDetails, post: e.target.value }) }} />
                        <TextField label="School" value={hofDetails.school} onChange={(e) => { setHofDetails({ ...hofDetails, school: e.target.value }) }} />
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            defaultValue="Default Value"
                            value={hofDetails.description} onChange={(e) => { setHofDetails({ ...hofDetails, description: e.target.value }) }}
                        />
                        <Button variant='contained' onClick={addHofObject} disabled={masterLoading || loading} disableElevation>Add</Button>
                    </Stack>

                </Box>
            </div>
        </div>
    )
}
