import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import { BackdropLoader } from '../UniversalFunctions';

export default function GalleryHome() {

    const navigate = useNavigate()
    const [imageURLs, setImageURLs] = useState([]);
    const [Loading, setLoading] = useState(false)
    // console.log(imageURLs);

    useEffect(() => {
        const getImageURLs = async () => {
            setLoading(true)
            const urls = await fetchImageURLs();
            setImageURLs(urls);
            setLoading(false)
        };
        getImageURLs();
    }, []);


    // fetching the images from the database 
    const fetchImageURLs = async () => {
        try {
            const querySnapshot = await getDocs(query(collection(databaseFirestore, 'gallery'), orderBy("imgAdded", "desc")));
            const imageURLs = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                imageURLs.push({
                    imgID: doc.id,
                    imgData: data
                }); // Use imageURLs instead of imageURL
            });
            return imageURLs; // Return imageURLs instead of imageURL
        } catch (error) {
            console.error('Error fetching image URLs from Firestore:', error);
            return [];
        }
    };

    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Stack direction={'row'} alignItems={'center'}>
                            <IconButton onClick={() => { navigate('/') }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Gallery</Typography>
                        </Stack>
                        <Button variant='contained' color='primary' onClick={() => { navigate("/addimages") }} disableElevation>Add Images</Button>
                    </Stack>
                    <br />
                    {/* <Typography variant='subtitle1' mb={2} color="primary">Tap to change the banners </Typography> */}

                    <Box display={'flex'} flexWrap={'wrap'} gap={2}>
                        {
                            imageURLs !== undefined && imageURLs !== null ?
                                imageURLs.map((img) => {
                                    return <ImageContainer url={img.imgData.imgURL} id={img.imgID} />
                                })
                                : ""
                        }
                    </Box>
                </Box>
            </div>
        </div>
    )
}

function ImageContainer(props) {

    const navigate = useNavigate()

    // function for deleting the image
    const deleteImg = async () => {
        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this image?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            await deleteDoc(doc(databaseFirestore, 'gallery', props.id)).then((res) => {
                navigate(0)
            })
            // console.log('Image URL deleted from Firestore successfully.');
        }
        catch (error) {
            console.error('Error deleting image URL from Firestore:', error);
        }
    }

    return <Box width={'30%'} minWidth={110} height={{ md: '200px', sm: '150px', xs: '150px' }} position="relative" borderRadius={2} bgcolor={"lightgrey"} overflow="hidden" >
        <IconButton sx={{ position: 'absolute', bottom: '10px', right: '10px', bgcolor: '#EA8F07' }} onClick={deleteImg}>
            <DeleteIcon fontSize='small' color='secondary' />
        </IconButton>
        <img src={props.url} alt="image url" height="100%" width="100%" style={{ objectFit: 'cover' }} />
    </Box>
}