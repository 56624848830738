import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, deleteDoc, doc, getDocs, setDoc, orderBy, query, limit, startAfter } from 'firebase/firestore';
import Delete from '@mui/icons-material/Delete';
import { BackdropLoader, PageWrapper } from '../UniversalFunctions';


export default function Blogs() {
    const navigate = useNavigate()
    const [Data, setData] = useState(null)
    const [lastDoc, setLastDoc] = useState(null);
    const [isListOver, setisListOver] = useState(false)
    const [Loading, setLoading] = useState(false)

    // console.log(Data);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true)
            const initialData = await FetchData();
            setData(initialData);
            setLoading(false)
        };
        fetchInitialData();
    }, []);

    const FetchData = async () => {
        try {
            const dataref = collection(databaseFirestore, 'blogs');
            // query(dataref, orderBy("createdAt", "desc"))
            const queryRef = lastDoc !== null ? query(dataref, orderBy("createdAt", "desc"), startAfter(lastDoc), limit(10)) : query(dataref, orderBy("createdAt", "desc"), limit(10))
            const querySnapshot = await getDocs(queryRef);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                fetchedData.push({
                    data: data,
                    id: doc.id
                });
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            if (lastVisible === undefined) { setisListOver(true) }
            setLastDoc(lastVisible);
            return fetchedData;
        } catch (error) {
            alert("Not found")
            return [];
        }
    };


    const loadMoreData = async () => {
        try {
            setLoading(true)
            const newData = await FetchData();
            setData((prevData) => [...prevData, ...newData]);
            setLoading(false)
        } catch (error) {
            alert("Not found")
        }
    };
    return <PageWrapper title={'Your Blogs'} buttonTitle={"Add new blog"} backdropLoading={Loading} backToHome={true} buttonAction={() => { navigate("/addblog") }}>
        <br />
        <Typography variant='subtitle1' mb={2} >All blogs </Typography>
        <Stack>
            {
                Data !== undefined && Data !== null ?
                    Data.map((hof, index) => {
                        return <BlogTile key={index} srNo={index + 1} id={hof.id} heading={hof.data?.title} date={hof.data?.createdAt} />
                    })
                    : ""
            }
        </Stack>
        {!isListOver ? <Button variant='outlined' fullWidth color='primary' onClick={loadMoreData} disableElevation>load more</Button> : ''}
    </PageWrapper>
}


function BlogTile(props) {
    const navigate = useNavigate()

    // function for deleting the image
    const deleteHof = async () => {
        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this Blog?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            await deleteDoc(doc(databaseFirestore, 'blogs', props.id)).then((res) => {
                navigate(0)
            })
            // console.log('deleted from Firestore successfully.');
        }
        catch (error) {
            console.error('Error deleting from Firestore:', error);
        }
    }

    return <Box p={2}>
        <Stack direction={'row'} >
            <Box width={'40px'}>
                <Typography variant='body2'>{props.srNo}</Typography>
            </Box>
            <Box width={'100%'}>
                <Typography variant='body2'>{props.heading}</Typography>
                <Typography variant='caption' color="grey">Posted on : {props.date?.toDate()?.toString()?.slice(0, 21)}</Typography>

            </Box>
            <Box>
                <IconButton onClick={deleteHof}>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Stack>
        <br />
        <Divider />
    </Box>
}