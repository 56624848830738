import React, { useState } from 'react'
import '../Styles/login.css'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthVariable } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import { Button, InputAdornment, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Email, LoginOutlined, Password } from '@mui/icons-material'

export default function Login() {
    const [UserCred, setUserCred] = useState({
        email: "",
        password: ""
    })
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)

    // LOGGING IN THE USER BY TAKING USER EMAIL AND PASSWORD. 
    const LOGGIN_IN = () => {
        if (UserCred.email === "" || UserCred.password === "") {
            alert("Enter user credentials")
            return
        }
        setLoading(true)
        signInWithEmailAndPassword(AuthVariable, UserCred.email, UserCred.password).then((user) => {
            // console.log(user);
            navigate('/', { replace: true })
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            alert("Invalid credentials")
            setLoading(false)
        })
    }


    return (
        <div className="loginWrapper">
            <img src="https://ik.imagekit.io/hgfugmm0dbi/VidhyaBharti/Logo_KTBlAbf6S.png?updatedAt=1714132682578" alt="logo"  width="150px" />
            <div className="form">
                <Stack spacing={2} direction="column" alignItems='center'>
                    <TextField
                        placeholder="Enter Admin Email"
                        value={UserCred.email}
                        onChange={(e) => { setUserCred({ ...UserCred, email: e.target.value }) }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email sx={{ width: "18px" }} />
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        placeholder="Enter Password"
                        value={UserCred.password}
                        onChange={(e) => { setUserCred({ ...UserCred, password: e.target.value }) }}
                        fullWidth
                        type='password'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password sx={{ width: "18px" }} />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={LOGGIN_IN}
                        disableElevation
                        disabled={Loading}
                        fullWidth
                        size='large'
                        sx={{ height: "54px" }}
                        startIcon={<LoginOutlined sx={{ width: "16px" }} />}
                    >
                        Login
                    </Button>
                </Stack>
            </div>
        </div>
    )
}
