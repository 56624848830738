export function Permissions(accessLevel) {
    const permissionToBeReturn = {
        AddBulkSchools: accessLevel == 0 ? true : false,
        AddUser: accessLevel == 0 ? true : false,
        Home: true,
        Banner: accessLevel == 1 || accessLevel == 0 ? true : false,
        GalleryHome: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddImage: accessLevel == 1 || accessLevel == 0 ? true : false,
        HOFhome: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddHOF: accessLevel == 1 || accessLevel == 0 ? true : false,
        Eventhome: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddEvent: accessLevel == 1 || accessLevel == 0 ? true : false,
        Notices: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddNotice: accessLevel == 1 || accessLevel == 0 ? true : false,
        AboutBase: accessLevel == 1 || accessLevel == 0 ? true : false,
        CreateForm: accessLevel == 1 || accessLevel == 0 ? true : false,
        FillForm: accessLevel == 2 ? true : false,
        Forms: accessLevel == 1 || accessLevel == 0 ? true : false,
        FormResponses: accessLevel == 1 || accessLevel == 0 ? true : false,
        SchoolList: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddSchool: accessLevel == 1 || accessLevel == 0 ? true : false,
        Blogs: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddBlog: accessLevel == 1 || accessLevel == 0 ? true : false,
        Downloads: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddDownload: accessLevel == 1 || accessLevel == 0 ? true : false,
        SchoolUserForms: accessLevel == 2 ? true : false,
        Response: accessLevel == 2 ? true : false,
        Syllabus: accessLevel == 1 || accessLevel == 0 ? true : false,
        AddClass: accessLevel == 1 || accessLevel == 0 ? true : false,
        Material: accessLevel == 1 || accessLevel == 0 ? true : false,
    }
    return permissionToBeReturn
}