import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router'
import { databaseFirestore, storage } from '../../Firebase';
import { Timestamp, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { BackdropLoader } from '../UniversalFunctions';

export default function Banner() {
    const navigate = useNavigate()
    const [imageURLs, setImageURLs] = useState(['', '', '', '']);
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        const getImageURLs = async () => {
            setLoading(true)
            const urls = await fetchImageURLs();
            setImageURLs(urls);
            setLoading(false)
        };
        getImageURLs();
    }, []);


    const fetchImageURLs = async () => {
        try {
            const querySnapshot = await getDocs(collection(databaseFirestore, 'homeCarousel'));
            const imageURLs = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                imageURLs.push(data.imgURL); // Use imageURLs instead of imageURL
            });
            return imageURLs; // Return imageURLs instead of imageURL
        } catch (error) {
            console.error('Error fetching image URLs from Firestore:', error);
            return [];
        }
    };

    return (
        <div className="appContainer">
            {Loading ? <BackdropLoader /> : ""}
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => { navigate(-1) }}>
                            <ArrowBack color='primary' fontSize='small' />
                        </IconButton>
                        <Typography variant='h6' color="primary" textTransform="uppercase">Change App Banner </Typography>
                    </Stack>
                    <br />
                    <Typography variant='body1' mb={1} color="primary"><b>Tap to change the banners</b> </Typography>
                    <Typography variant="body2" color='grey' gutterBottom>Note - Please ensure to upload the image with an aspect ratio of 9:16 or a resolution of 1920x1080. Otherwise, the image will not be displayed properly.</Typography>
                    <Typography variant="body2" gutterBottom color='grey'>नोट - कृपया सुनिश्चित करें कि आप 9:16 के आस्पेक्ट रेशियो या 1920x1080 के रिज़ॉल्यूशन के साथ छवि अपलोड करें। अन्यथा, छवि सही ढंग से दिखाई नहीं देगी।</Typography>
                    <br />
                    <div className="image-containers">
                        {[0, 1, 2, 3].map((index) => (
                            <Container
                                key={index}
                                containerIndex={index}
                                imageURL={imageURLs[index]}
                                setImageURL={(url) => {
                                    const newURLs = [...imageURLs];
                                    newURLs[index] = url;
                                    setImageURLs(newURLs);
                                }}
                            />
                        ))}
                    </div>
                </Box>
            </div>
        </div>
    )
}


const Container = ({ containerIndex, imageURL, setImageURL }) => {
    const [loading, setLoading] = useState(false);

    const handleChangeImage = async (e) => {
        const selectedImage = e.target.files[0];

        // if no image is selected then eat 5 star do nothing 
        if (!selectedImage) return;

        // Check if the selected file type is allowed
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedTypes.includes(selectedImage.type)) {
            alert('Only PNG, JPEG, and JPG files are allowed.');
            return;
        }

        // Check if the selected file size is less than 4MB
        const maxSize = 3 * 1024 * 1024; // 4MB in bytes
        if (selectedImage.size > maxSize) {
            alert('Image size must be less than 3MB.');
            return;
        }

        // Upload the selected image to Firebase Storage
        const imageRef = ref(storage, `images/container${containerIndex}`);
        setLoading(true);
        await uploadBytes(imageRef, selectedImage);

        // Get the download URL of the uploaded image
        const downloadURL = await getDownloadURL(imageRef);
        setImageURL(downloadURL);

        // Inside handleChangeImage function after obtaining imageURL
        storeImageURLInFirestore(downloadURL, `img${containerIndex}`);

        setLoading(false);
    };

    const storeImageURLInFirestore = async (imageURL, docId) => {
        try {
            const docRef = doc(databaseFirestore, 'homeCarousel', docId);
            const myTimestamp = Timestamp.fromDate(new Date());
            await setDoc(docRef, { imgURL: imageURL, imgAdded: myTimestamp });
            // console.log('Image URL stored in Firestore successfully.');
        } catch (error) {
            console.error('Error storing image URL in Firestore:', error);
        }
    };

    return (
        <div className="container" onClick={() => document.getElementById(`input-${containerIndex}`).click()}>
            <Box width='100%' height={300} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#D9D9D9'} borderRadius={4} overflow={'hidden'}>
                {loading || imageURL === "" ? (
                    <CircularProgress />
                ) : imageURL ? (
                    <img src={imageURL} alt={`Image ${containerIndex}`} width={'100%'} />
                ) : (
                    <p>No Image, Tap to add image</p>
                )}
                <input
                    id={`input-${containerIndex}`}
                    type="file"
                    accept="image/*"
                    onChange={handleChangeImage}
                    style={{ display: 'none' }}
                />
            </Box>
            <br />
        </div>
    );
};
