import Home from './Components/Home';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import PageNotFound from './Components/PageNotFound';
import ProtectedLogin from './Components/ProtectedLogin';
import Banner from './Components/WebsiteBanner/Banner';
import GalleryHome from './Components/Gallery/GalleryHome';
import AddImage from './Components/Gallery/AddImage';
import HOFhome from './Components/HallOffame/HOFhome';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import AddHOF from './Components/HallOffame/AddHOF';
import Blogs from './Components/Blogs/Blogs';
import AddBlog from './Components/Blogs/AddBlog';
import Eventhome from './Components/RecentEvents/Eventhome';
import AddEvent from './Components/RecentEvents/AddEvent';
import AddBulkSchools from './Components/SuperAdminComponents/AddBulkSchools';
import SuperAdminProtected from './Components/SuperAdminProtected';
import Notices from './Components/Notices/Notices';
import AddNotice from './Components/Notices/AddNotice';
import AboutBase from './Components/About/AboutBase';
import AboutSchool from './Components/About/AboutSchool';
import Stats from './Components/About/Stats';
import BlogCategories from './Components/About/BlogCategories';
import NoticeTypes from './Components/About/NoticeTypes';
import SocialMedia from './Components/About/SocialMedia';
import ContactDetails from './Components/About/ContactDetails';
import SchoolList from './Components/Schools/SchoolList';
import AddSchool from './Components/Schools/AddSchool';
import Downloads from './Components/downloads/Downloads';
import AddDownload from './Components/downloads/AddDownload';
import CreateForm from './Components/Forms/CreateForm';
import FillForm from './Components/Forms/FillForm';
import Forms from './Components/Forms/Forms';
import FormResponses from './Components/Forms/FormResponses';
import Response from './Components/SchoolUsers/Response';
import SchoolUserForms from './Components/SchoolUsers/SchoolUserForms';
import AddUser from './Components/SuperAdminComponents/AddUser';
import Syllabus from './Components/Syllabus/Syllabus';
import AddClass from './Components/Syllabus/AddClass';
import Material from './Components/Syllabus/Material';

// Keep in mind whenever you are adding any new route to this page. Add a permssion corresponding to that page into the Permission.js to make sure who can visit that page. 

function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ProtectedLogin child={"Home"}><Home /></ProtectedLogin>} />
        <Route path='/banners' element={<ProtectedLogin child={"Banner"}><Banner /></ProtectedLogin>} />
        <Route path='/gallery' element={<ProtectedLogin child={"GalleryHome"}><GalleryHome /></ProtectedLogin>} />
        <Route path='/addimages' element={<ProtectedLogin child={"AddImage"}><AddImage /></ProtectedLogin>} />
        <Route path='/halloffame' element={<ProtectedLogin child={"HOFhome"}><HOFhome /></ProtectedLogin>} />
        <Route path='/addhof' element={<ProtectedLogin child={"AddHOF"}><AddHOF /></ProtectedLogin>} />
        <Route path='/events' element={<ProtectedLogin child={"Eventhome"}><Eventhome /></ProtectedLogin>} />
        <Route path='/addevent' element={<ProtectedLogin child={"AddEvent"}><AddEvent /></ProtectedLogin>} />
        <Route path='/notices' element={<ProtectedLogin child={"Notices"}><Notices /></ProtectedLogin>} />
        <Route path='/addnotice' element={<ProtectedLogin child={"AddNotice"}><AddNotice /></ProtectedLogin>} />

        <Route path='/addbulkschools' element={<ProtectedLogin child={"AddBulkSchools"}><AddBulkSchools /></ProtectedLogin>} />
        <Route path='/about' element={<ProtectedLogin child={"AboutBase"}><AboutBase /></ProtectedLogin>}>
          <Route path='/about/aboutschool' element={<AboutSchool />} />
          <Route path='/about/stats' element={<Stats />} />
          <Route path='/about/blogcategories' element={<BlogCategories />} />
          <Route path='/about/noticetypes' element={<NoticeTypes />} />
          <Route path='/about/socialmedia' element={<SocialMedia />} />
          <Route path='/about/contactdetails' element={<ContactDetails />} />
        </Route>

        <Route path='/createform' element={<ProtectedLogin child={"CreateForm"}><CreateForm /></ProtectedLogin>} />
        <Route path='/fillform' element={<ProtectedLogin child={"FillForm"}><FillForm /></ProtectedLogin>} />
        <Route path='/forms' element={<ProtectedLogin child={"Forms"}><Forms /></ProtectedLogin>} />
        <Route path='/formresponses' element={<ProtectedLogin child={"FormResponses"}><FormResponses /></ProtectedLogin>} />
        <Route path='/schools' element={<ProtectedLogin child={"SchoolList"}><SchoolList /></ProtectedLogin>} />
        <Route path='/addnewschool' element={<ProtectedLogin child={"AddSchool"}><AddSchool /></ProtectedLogin>} />
        <Route path='/blogs' element={<ProtectedLogin child={"Blogs"}><Blogs /></ProtectedLogin>} />
        <Route path='/addblog' element={<ProtectedLogin child={"AddBlog"}><AddBlog /></ProtectedLogin>} />
        <Route path='/downloads' element={<ProtectedLogin child={"Downloads"}><Downloads /></ProtectedLogin>} />
        <Route path='/addfile' element={<ProtectedLogin child={"AddDownload"}><AddDownload /></ProtectedLogin>} />


        {/* route for the schools which iare going to only see only one button of form */}
        <Route path='/schoolforms' element={<ProtectedLogin child={"SchoolUserForms"}><SchoolUserForms /></ProtectedLogin>} />
        <Route path='/responses' element={<ProtectedLogin child={"Response"}><Response /></ProtectedLogin>} />
        <Route path='/adduser' element={<ProtectedLogin child={"AddUser"}><AddUser /></ProtectedLogin>} />
       
        <Route path='/syllabus' element={<ProtectedLogin child={"Syllabus"}><Syllabus /></ProtectedLogin>} />
        <Route path='/addclass' element={<ProtectedLogin child={"AddClass"}><AddClass /></ProtectedLogin>} />
        <Route path='/classmaterial' element={<ProtectedLogin child={"Material"}><Material /></ProtectedLogin>} />

        <Route path='/login' element={<Login />} />
        <Route path='/*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </>
}

export default App;
