import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { PageWrapper } from '../UniversalFunctions'

export default function AboutBase() {
    const navigate = useNavigate()
    const sx = {
        height: '70px',
        justifyContent: "flex-start",
        fontWeight: 800
    }
    const [CategorySelected, setCategorySelected] = useState('About School')

    function handleChipClick(category, route) {
        setCategorySelected(category)
        navigate(route)
    }
    return <PageWrapper title={'Edit basic details'} secondaryTitle={'You can edit the basic details of the school from here. Tap the section to edit the details of that section'}>
        <Stack direction={'row'} gap={1} flexWrap={'wrap'} pt={2} pb={2}>
            <Chip label={"About School"} onClick={() => { handleChipClick("About School", "/about/aboutschool") }} color={CategorySelected === "About School" ? "primary" : "secondary"} />
            <Chip label={"Stats"} onClick={() => { handleChipClick("Stats", "/about/stats") }} color={CategorySelected === "Stats" ? "primary" : "secondary"} />
            <Chip label={"Blogs Categories"} onClick={() => { handleChipClick("Blogs Categories", "/about/blogcategories") }} color={CategorySelected === "Blogs Categories" ? "primary" : "secondary"} />
            <Chip label={"Notice Types"} onClick={() => { handleChipClick("Notice Types", "/about/noticetypes") }} color={CategorySelected === "Notice Types" ? "primary" : "secondary"} />
            <Chip label={"Social Media"} onClick={() => { handleChipClick("Social Media", "/about/socialmedia") }} color={CategorySelected === "Social Media" ? "primary" : "secondary"} />
            <Chip label={"Contact details"} onClick={() => { handleChipClick("Contact details", "/about/contactdetails") }} color={CategorySelected === "Contact details" ? "primary" : "secondary"} />
        </Stack>
        <Outlet />
    </PageWrapper>

}

export function StyledBox({ children }) {
    return <Box p={2} bgcolor="#f5f5f5" borderRadius={3}>
        {children}
    </Box>
}




