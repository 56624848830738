import React, { useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { databaseFirestore, storage } from '../../Firebase';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const AddDownload = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    // const storage = getStorage();
    // const db = getFirestore();
    const navigate = useNavigate()
    const maxSize = 100 * 1024 * 1024; // 100 MB in bytes

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size > maxSize) {
            alert('File size exceeds 100MB limit');
        } else {
            setFile(selectedFile);
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file || !title) {
            alert('Please fill in all fields');
            return;
        }
        setLoading(true);
        const fileName = `${Date.now()}_${file.name}`;
        const fileRef = ref(storage, `downloads/${fileName}`);


        try {
            await uploadBytes(fileRef, file);
            const fileUrl = await getDownloadURL(fileRef);
            await addDoc(collection(databaseFirestore, 'downloads'), {
                createdAt: serverTimestamp(),
                fileName: file.name,
                fileType: file.type,
                fileUrl,
                title,
            });
            alert('File uploaded successfully');
            setFile(null);
            setTitle('');
        } catch (error) {
            alert('Error uploading file');
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="appContainer">
            <div className="appWrapper">
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => { navigate(-1) }}>
                                <ArrowBack color='primary' fontSize='small' />
                            </IconButton>
                            <Typography variant='h6' color="primary" textTransform="uppercase">Add new </Typography>
                        </Stack>
                    </Stack>
                    <br />
                    <Typography variant="h5" > Add Download </Typography>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            label="Title"
                            value={title}
                            onChange={handleTitleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <input
                            accept="*"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-file">
                            <Button variant="contained" color="secondary" component="span" disableElevation >
                                Attach File
                            </Button>
                        </label>
                        {file && <Typography variant="body2" gutterBottom>File Name : {file.name}</Typography>}
                        <Box mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disableElevation
                                color="primary"
                                disabled={loading}
                                startIcon={loading && <CircularProgress size={20} />}
                            >
                                {loading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
};

export default AddDownload;